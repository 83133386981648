import { Accordion, AccordionDetails, AccordionSummary, Box, Button, TextField, Typography } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import React from "react";

const GiftCard = ({ setGiftCard, giftCard }) => {
  const handleChange = ({ target: { name, value } }) => {
    setGiftCard({ ...giftCard, [name]: value });
  };
  console.log(giftCard);
  return (
    <Box mt={2}>
    <Accordion>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Gift Card</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box width={"100%"}>
          <Box flexDirection="row" display="flex" marginBottom={1} justifyContent="space-between">
              <TextField
                label="From"
                name="fromName"
                onChange={handleChange}
                value={giftCard?.fromName ?? ""}
                size="small"
                variant="outlined"
              />
              <TextField
                label="To"
                name="toName"
                onChange={handleChange}
                value={giftCard?.toName ?? ""}
                size="small"
                variant="outlined"
              />
            </Box>
            <TextField
              multiline
              fullWidth
              variant="outlined"
              name="message"
              onChange={handleChange}
              value={giftCard?.message ?? ""}
            />
            <Box
              variant="contained"
              component={Button}
              margin={1}
              onClick={() => setGiftCard(null)}
            >
              Clear
            </Box>
          </Box>
        </AccordionDetails>
    </Accordion>
  </Box>
  );
};

export default GiftCard;
