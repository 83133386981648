import { Box, Button } from "@mui/material";
import { ConfirmationModal, ErrorAlert } from "../../common";
import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";

import { Link } from "react-router-dom";
// components
import PaymentLink from "./PaymentLink.js";
import { PaymentMethod } from "../../../constants";
import { STATUSES } from "../../../Graphql/Queries/Orders";
import { UPDATE_ORDER } from "../../../Graphql/Mutations/Orders";
import { generateRawReceipt } from "./Receipt";

const Footer = ({ order }) => {
  const CANCELLED = "canceled";
  const COMPLETED = "delivered";
  const client = useApolloClient();

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [error, setError] = useState(null);
  const [updateOrder] = useMutation(UPDATE_ORDER);
  const { orderStatuses: statuses } = client.readQuery({ query: STATUSES });

  const cancelOrder = async () => {
    try {
      await updateOrder({
        variables: {
          id: order.id,
          status: statuses.find(
            status => status.title.toLowerCase() === CANCELLED
          ).id
        }
      });
      setOpenConfirmation(false);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Box
      display="flex"
      mt={3}
      style={{
        justifyContent: "space-between",
        flexFlow: "row wrap"
      }}
    >
      <div className="flex-grow">
        {PaymentMethod[order.paymentMethod] === PaymentMethod.ONLINE && <PaymentLink order={order} />}
        <Button
          color="secondary"
          variant="contained"
          className="mx-2 my-2"
          onClick={() => generateRawReceipt(order)}
        >
          Download Receipt
        </Button>
      </div>

      <div>
        {order.status.title.toLowerCase() === COMPLETED && (
          <Link to={`/returns/${order.id}/create`}>
            <Button color="primary" variant="contained" className="mx-2 my-2">
              Return & Exchange
            </Button>
          </Link>
        )}

        {order.status.title.toLowerCase() !== CANCELLED && (
          <Button
            color="secondary"
            className="mx-2 my-2"
            onClick={() => setOpenConfirmation(true)}
          >
            Cancel Order
          </Button>
        )}
      </div>
      <ConfirmationModal
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        action={cancelOrder}
      />
      <ErrorAlert error={error} handleClose={() => setError(null)} />
    </Box>
  );
};

export default Footer;
