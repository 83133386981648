import {
  Hidden,
  IconButton,
  Input,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

const ProductRow = ({ product, index, handleChange, removeProduct, isConsignment }) => {
  const sizes = product.variations.map((variation, index) => {
    return (
      <MenuItem value={index} key={index}>
        {variation.sizeStr}
      </MenuItem>
    );
  });

  return (
    <TableRow>
      <Hidden xsDown>
        <TableCell padding="checkbox">
          <img src={product.image} width="70px" alt={product.title} />
        </TableCell>
      </Hidden>
      <TableCell>{product.title}</TableCell>
      <TableCell padding="checkbox">
        <Select
          name="selectedVariation"
          defaultValue={0}
          onChange={e => {
            handleChange(index, e.target.name, e.target.value);
          }}
        >
          {sizes}
        </Select>
      </TableCell>
      <TableCell style={{ minWidth: "70px" }}>
        <Input
          label="Quantity"
          variant="outlined"
          name="quantity"
          defaultValue={1}
          type="number"
          fullWidth
          onChange={e => {
            handleChange(index, e.target.name, e.target.value);
          }}
        />
      </TableCell>
      <TableCell style={{ minWidth: "70px" }}>
        <Input
          label="Cost"
          name="cost"
          type="number"
          onChange={e => {
            handleChange(index, e.target.name, e.target.value);
          }}
        />
        {isConsignment &&
        <Typography variant="caption">
          Profit: {product.variations[product.selectedVariation].price - product.cost}
        </Typography>
        }
      </TableCell>
      <TableCell align="right" padding="none">
        <IconButton
          aria-label="delete"
          onClick={() => removeProduct(index)}
          className="p-1 w-1"
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ProductRow;
