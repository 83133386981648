import {
  PURCHASES,
  SOURCES_STATUSES
} from "../../../Graphql/Queries/Purchases";
import React, { useState } from "react";

import CreateButton from "../../common/CreateButton";
import ErrorMessage from "../../common/ErrorMessage";
import Filters from "./Filters";
import Loading from "../../common/Loading";
import PurchaseTable from "./PurchaseTable";
import { Toolbar } from "@mui/material";
import { useQuery } from "@apollo/client";

const List = () => {
  const {
    data: purchseData,
    loading: purchaseLoading,
    error: purchseError
  } = useQuery(PURCHASES);
  const { loading, error } = useQuery(SOURCES_STATUSES);

  const [values, setValues] = useState({
    status: "0",
    source: "0",
    costKwd: false,
    shipping: false
  });

  if (purchaseLoading || loading) return <Loading />;
  if (purchseError || error) return <ErrorMessage error={purchseError} />;

  const purchases = purchseData.purchases;

  const filteredPurchases = purchases.filter(purchase => {
    if (values.source !== "0" && purchase.source.id !== values.source)
      return false;
    if (
      values.status !== "0" &&
      !purchase.purchaseItems.some(
        purchaseItem => purchaseItem.status.id === values.status
      )
    )
      return false;

    if (values.costKwd && purchase.costKwd) return false;
    if (
      values.shipping &&
      purchase.purchaseItems.some(purchaseItem => purchaseItem.shipping)
    )
      return false;

    return true;
  });

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
    setValues(oldValues => ({ ...oldValues, [name]: value ? value : checked }));
  };

  return (
    <>
      <Filters handleChange={handleChange} />
      <CreateButton url="/purchases/create" text="Create Purchase" />
      <Toolbar />
      <PurchaseTable purchases={filteredPurchases} />
    </>
  );
};

export default List;
