import React, { useState } from "react";

import { AVAILABLE_PRODUCTS } from "../../../../Graphql/Queries/Products";
// material UI
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
// apollo
import { useLazyQuery } from "@apollo/client";

const ProductSearch = ({ addProduct }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [getProducts, { data, loading }] = useLazyQuery(AVAILABLE_PRODUCTS, {
    variables: {
      variables: {
        active: true,
        new:true
      }
    },
    fetchPolicy: "cache-and-network"
  });

  let options = data?.products ?? []

  const handleChange = ({target}) => {
    if (target.value.length === 3) getProducts({variables:{active:true, new: true, search:target.value}})
    else options = options.filter(option => option.title.includes(target.value))
  }

  const handleSelect = (e, product) => {
    if (product) {
      addProduct(product);
      setSelectedProduct(null);
    }
  };
  return (
    <Autocomplete
      options={options}
      groupBy={option => option.productType.title}
      getOptionLabel={option => option.title}
      getOptionDisabled={option =>
        !option.variations.some(variation => variation.inStock)
      }
      loading={loading}
      onChange={handleSelect}
      openOnFocus
      value={selectedProduct}
      renderInput={params => (
        <TextField
          {...params}
          label="Product"
          variant="outlined"
          color="primary"
          size="small"
          onChange={handleChange}
        />
      )}
    />
  );
};

export default ProductSearch;
