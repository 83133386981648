import {
  Hidden,
  IconButton,
  Input,
  MenuItem,
  Select,
  TableCell,
  TableRow
} from "@mui/material";
import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";

const ProductRow = ({
  product,
  index,
  handleVariationChange,
  handleQuantityChange,
  removeProduct,
  inventory
}) => {
  const defaultVariation =
    product.variations.length === 1
      ? 0
      : product.variations.findIndex(variation => variation.inStock);
  const [selectedVariation, setSelectedVariation] = useState(defaultVariation);
  const [quantity, setQuantity] = useState(product.quantity);

  // available sizes for the size dropdown
  const sizes = product.variations.map((variation, index) => {
    return (
      <MenuItem value={index} key={index} disabled={!variation.inStock}>
        {variation.sizeStr}
      </MenuItem>
    );
  });

  return (
    <TableRow>
      <Hidden xsDown>
        <TableCell padding="checkbox">
          <img
            src={product.variation?.product.image ?? product.image}
            width="70px"
            alt={product.variation?.product.title ?? product.title}
          />
        </TableCell>
      </Hidden>
      <TableCell>{product.variation?.product.title ?? product.title}</TableCell>
      <TableCell padding="checkbox">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={defaultVariation}
          onChange={e => {
            setSelectedVariation(e.target.value);
            setQuantity(1);
            handleVariationChange(index, e.target.value);
          }}
        >
          {sizes}
        </Select>
      </TableCell>
      <TableCell style={{ minWidth: "70px" }}>
        <Input
          label="Quantity"
          variant="outlined"
          value={quantity}
          type="number"
          fullWidth
          inputProps={{ min: 1, max: inventory }}
          onChange={e => {
            const quantity =
              e.target.value > inventory ? inventory : e.target.value;
            setQuantity(quantity);
            handleQuantityChange(index, quantity);
          }}
        />
      </TableCell>
      <TableCell>
        {product.variations[selectedVariation].salePrice
          ? product.variations[selectedVariation].salePrice * quantity
          : product.variations[selectedVariation].price * quantity}{" "}
        KD
      </TableCell>
      <TableCell align="right" padding="none">
        <IconButton
          aria-label="delete"
          onClick={() => removeProduct(index)}
          className="p-1 w-1"
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ProductRow;
