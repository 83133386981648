import gql from "graphql-tag";

export const CONTACT_FIELDS = gql`
  fragment ContactFields on AddressType {
    id
    phoneNumber
    address
    address2
    country {
      name
    }
    city
  }
`;

export const ORDER_FIELDS = gql`
  fragment OrderFields on OrderType {
    id
    shipping
    total
    onlinePaymentMethod
    isPickup
    discount
    isGiftWrapped
    status {
      id
      title
      sequence
      color
    }
    source {
      title
      color
    }
    createdDate
    paymentMethod
    paymentStatus
    user {
      username
      email
      firstName
      lastName
    }
    address {
      user {
        id
        email
      }
      id
      firstName
      lastName
      phoneNumber
      address
      address2
      country {
        id
        name
      }
      city {
        id
        name
        shipping
      }
    }
    orderItems {
      id
      quantity
      price
      total
      isGiftWrapped
      inventorySet{
        id
      }
      variation {
        id
        price
        product {
          id
          title
          image
          brand {
            title
          }
        }
        sizeStr
      }
    }
  }
`;
