import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme
} from "@mui/material";

import { ErrorMessage } from "../../common";
import React from "react";
import VariationRow from "./VariationRow";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const PRODUCT = gql`
  query($productId: Int!) {
    product(productId: $productId) {
      id
      image
      title
      variations {
        id
        price
        salePrice
        inventory
        incomingInventory
        sizeStr
        inStock
      }
    }
  }
`;

const VariationsTable = ({ productId }) => {
  const { data, loading, error } = useQuery(PRODUCT, {
    variables: { productId }
  });
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  if (loading)
    return (
      <Box fontWeight="bold" fontSize={16} textAlign="center" margin={5}>
        Loading...
      </Box>
    );
  if (error) return <ErrorMessage error={error} />;

  const { product } = data;

  return (
    <Box  display={"flex"} flexDirection={isMobile ? "column" : "row-reverse"}>
      <Box m={3}>
        <img src={product.image} alt={product.title}/>
      </Box>  
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">Available</TableCell>
            <TableCell align="center">Incoming</TableCell>
            <TableCell align="right">Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {product.variations.map((variation, idx) => (
            <VariationRow key={idx} variation={variation} idx={idx} />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default VariationsTable;
