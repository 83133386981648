import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";

import ErrorMessage from "../../common/ErrorMessage";
import InventoryTable from "./InventoryTable";
import Loading from "../../common/Loading";
import { PRODUCT_TYPES } from "../../../Graphql/Queries/Products";
import { useQuery } from "@apollo/client";

const List = () => {
  const { loading, data, error } = useQuery(PRODUCT_TYPES);
  const [productType, setProductType] = useState(0);

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  const { productTypes } = data;

  return (
    <>
      <Tabs
        value={productType}
        onChange={(e, newValue) => setProductType(newValue)}
        indicatorColor="secondary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {productTypes.map((productType, index) => (
          <Tab label={productType.title} key={index} />
        ))}
      </Tabs>

      <InventoryTable productTypeId={productTypes[productType].id} />
    </>
  );
};

export default List;
