import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { COUNTRIES, SOURCES, STATUSES } from "../../../Graphql/Queries/Orders";

import React from "react";
import { paymentStatus } from "../../../constants";
import { useApolloClient } from "@apollo/client";
import { useState } from "react";

const Filters = ({ handleChange, handleSubmit }) => {
  const [search, setSearch] = useState("");
  const client = useApolloClient();
  const { orderSources: sources } = client.readQuery({
    query: SOURCES
  });
  const { orderStatuses: statuses } = client.readQuery({
    query: STATUSES
  });
  const { countries } = client.readQuery({
    query: COUNTRIES
  });

  const sourceOptions = sources.map(source => (
    <MenuItem key={source.title} value={source.title}>
      {source.title}
    </MenuItem>
  ));
  const statusOptions = statuses.map(status => (
    <MenuItem key={status.title} value={status.title}>
      {status.title}
    </MenuItem>
  ));
  const countryOptions = countries.map(country => (
    <MenuItem key={country.id} value={country.name}>
      {country.name}
    </MenuItem>
  ));

  const paymentOptions = Object.keys(paymentStatus).map(key => (
    <MenuItem key={key} value={key}>
      {paymentStatus[key]}
    </MenuItem>
  ));

  return (
    <>
      <Grid  container spacing={1} mb={3}>
        <Grid item xs={5}>
          <TextField
            label="order# or contact"
            name="search"
            size="small"
            fullWidth
            onChange={e => setSearch(e.target.value.toLowerCase())}
          />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={()=>handleSubmit(search)}
          >
            Retrieve Orders
          </Button>
        </Grid>
      </Grid>

      <TextField
        select
        label="Order Source"
        defaultValue="all"
        onChange={handleChange}
        name="source"
        size="small"
        className="col-xl-2 col-md-3 col-5 mx-2 my-1"
      >
        <MenuItem value="all">All</MenuItem>
        {sourceOptions}
      </TextField>
      <TextField
        select
        label="Order Status"
        defaultValue="all"
        onChange={handleChange}
        name="status"
        size="small"
        className="col-xl-2 col-md-3 col-5 mx-2 my-1"
      >
        <MenuItem value="all">All</MenuItem>
        {statusOptions}
      </TextField>
      <TextField
        select
        label="Payment Status"
        defaultValue="all"
        onChange={handleChange}
        name="payment"
        size="small"
        className="col-xl-2 col-md-3 col-5 mx-2 my-1"
      >
        <MenuItem value="all">All</MenuItem>
        {paymentOptions}
      </TextField>
      <TextField
        select
        label="Country"
        defaultValue="all"
        onChange={handleChange}
        name="country"
        size="small"
        className="col-xl-2 col-md-3 col-5 mx-2 my-1"
      >
        <MenuItem value="all">All</MenuItem>
        {countryOptions}
      </TextField>
    </>
  );
};

export default Filters;
