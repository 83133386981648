// UI
import { Box, Grid } from "@mui/material";
import React, { useState } from "react";

// graphQL
import { CREATE_ORDER } from "../../../Graphql/Mutations/Orders";
import { ErrorAlert } from "../../common";
import GiftCard from "./GiftCard";
import OrderSummary from "./OrderSummary";
import ProductSearch from "./Products/Search";
// components
import ProductsTable from "./Products/ProductsTable";
import { updateOrdersCache } from "../../../Graphql/Helpers";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

const Create = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [addedProducts, setAddedProducts] = useState([]);
  const [giftCard, setGiftCard] = useState(null);

  const [createOrder] = useMutation(CREATE_ORDER, {
    update: updateOrdersCache,
    onCompleted: data => history.push(`/orders/${data.createOrder.order.id}`)
  });
  const setCreateOrderInput = async orderData => {
    if (addedProducts.some(product => product.selectedVariation === null)){
      setError("Please select the size for all products")
      return
    }

    let input = {
      ...orderData,
      gift: giftCard,
      isGiftWrapped: giftCard || addedProducts.some(product=> product.isGiftWrapped) ? true : false,
      variations: addedProducts.map(product => {
        return {
          variationId: product.variations[product.selectedVariation].id,
          quantity: product.quantity,
          isGiftWrapped: product.isGiftWrapped
        };
      })
    };
    try {
      await createOrder({ variables: input });
    } catch (e) {
      setError(e.message);
    }
  };

  const addProduct = (product) => {
    const newProduct = {
      ...product,
      quantity: 1,
      selectedVariation: product.variations.length === 1 ? 0 :null,
      isGiftWrapped: false
    }
    setAddedProducts(products => [...products, newProduct]);
  };

  const removeProduct = index => {
    let newProducts = [...addedProducts];
    newProducts.splice(index, 1);
    setAddedProducts(newProducts);
  };

  const handleVariationChange = (productIndex, variationIndex) => {
    let newProducts = [...addedProducts];
    const product = {
      ...newProducts[productIndex],
      selectedVariation: variationIndex,
      quantity: 1
    };
    newProducts[productIndex] = product;
    setAddedProducts([...newProducts]);
  };

  const handleQuantityChange = (productIndex, quantity) => {
    let newProducts = [...addedProducts];
    let product = {
      ...newProducts[productIndex],
      quantity: quantity
    };
    newProducts[productIndex] = product;
    setAddedProducts(newProducts);
  };

  const handleGiftWrapChange = (productIndex, isGiftWrapped) => {
    let newProducts = [...addedProducts];
    let product = {
      ...newProducts[productIndex],
      isGiftWrapped
    };
    newProducts[productIndex] = product;
    setAddedProducts(newProducts);
  };

  const total = 
  (addedProducts.reduce((total, product) => product.selectedVariation !== null && product.isGiftWrapped ? total+5*product.quantity : total, 0)) + 
  (addedProducts.reduce(
    (total, product) =>
    product.selectedVariation !== null ?
      product.variations[product.selectedVariation].salePrice
        ? total +
          product.variations[product.selectedVariation].salePrice *
            product.quantity
        : total +
          product.variations[product.selectedVariation].price *
            product.quantity
    : total,
    0
  ));

  return (
    <Box pt={3}>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ProductSearch addProduct={addProduct} />
          <ProductsTable
            products={addedProducts}
            handleVariationChange={handleVariationChange}
            handleQuantityChange={handleQuantityChange}
            handleGiftWrapChange={handleGiftWrapChange}
            removeProduct={removeProduct}
          />
          <GiftCard giftCard={giftCard} setGiftCard={setGiftCard} />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <OrderSummary
            total={total}
            createAction={setCreateOrderInput}
            clearProducts={() => setAddedProducts([])}
          />
        </Grid>
      </Grid>

      <ErrorAlert error={error} handleClose={() => setError(null)} />
    </Box>
  );
};

export default Create;
