import { Grid, MenuItem, TextField } from "@mui/material";
import { PaymentMethod, paymentStatus } from "../../../../constants";

import React from "react";
import { STATUSES } from "../../../../Graphql/Queries/Orders";
import { useApolloClient } from "@apollo/client";

const OrderInfo = ({ order, updateOrder }) => {
  const CANCELLED = "canceled";
  const client = useApolloClient();
  const { orderStatuses: statuses } = client.readQuery({
    query: STATUSES
  });

  const handleChange = async ({ target: { name, value } }) => {
    try {
      await updateOrder({
        [name]: value
      });
    } catch (error) {
      console.log(error);
    }
  };

  const statusOptions = statuses.map(status => (
    <MenuItem
      key={status.id}
      value={status.id}
      disabled={status.sequence === null}
    >
      {status.title}
    </MenuItem>
  ));

  const paymentMethodOptions = Object.keys(PaymentMethod).map(option => (
    <MenuItem key={option} value={PaymentMethod[option]}>
      {PaymentMethod[option]}
    </MenuItem>
  ));

  const paymentStatusOptions = Object.keys(paymentStatus).map(key => (
    <MenuItem key={key} value={paymentStatus[key]}>
      {paymentStatus[key]}
    </MenuItem>
  ));

  return (
    <Grid container direction="row" spacing={5} alignItems="flex-start">
      <Grid item>
        <table>
          <tbody>
            <tr>
              <td className="py-3 font-weight-bold">Order</td>
              <td className="pl-3 py-3">#{order.id}</td>
            </tr>
            <tr>
              <td className="py-3 font-weight-bold">Date</td>
              <td className="pl-3 py-3">{order.createdDate}</td>
            </tr>
            <tr>
              <td className="py-3 font-weight-bold">Source</td>
              <td className="pl-3 py-3">{order.source.title}</td>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid item>
        <table>
          <tbody>
            <tr>
              <td className="py-2 font-weight-bold">Status</td>
              <td className="pl-3 py-2">
                <TextField
                  select
                  fullWidth
                  name="status"
                  size="small"
                  variant="outlined"
                  value={order.status.id}
                  onChange={handleChange}
                  disabled={order.status.title.toLowerCase() === CANCELLED}
                >
                  {statusOptions}
                </TextField>
              </td>
            </tr>
            <tr>
              <td className="py-2 font-weight-bold">Payment Source</td>
              <td className="pl-3 py-2">
                <TextField
                  select
                  fullWidth
                  name="paymentMethod"
                  size="small"
                  variant="outlined"
                  value={PaymentMethod[order.paymentMethod]}
                  onChange={handleChange}
                  disabled={order.status.title.toLowerCase() === CANCELLED}
                >
                  {paymentMethodOptions}
                </TextField>
              </td>
            </tr>
            <tr>
              <td className="py-2 font-weight-bold">Payment Status</td>
              <td className="pl-3 py-2">
                <TextField
                  select
                  fullWidth
                  name="paymentStatus"
                  size="small"
                  variant="outlined"
                  value={paymentStatus[order.paymentStatus]}
                  onChange={handleChange}
                  disabled={
                    order.status.title.toLowerCase() === CANCELLED ||
                    order.source.title === "Website"
                  }
                >
                  {paymentStatusOptions}
                </TextField>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

export default OrderInfo;
