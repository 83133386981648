import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    InputAdornment,
    MenuItem,
    TextField,
} from "@mui/material"
import { ConfirmationModal, Loading } from "../../common";
import React, { useState } from "react"
import { useMutation, useQuery } from "@apollo/client";

import { DELETE_CONSIGNMENT } from "../../../Graphql/Mutations/Purchases";
import gql from "graphql-tag";

const SOURCES = gql`
  query {
    sources {
      id
      title
    }
  }
`;

const UPDATE_CONSIGNMENT = gql`
mutation($consignmentId: Int, $total: Float, $sourceId: Int){
  updateConsignment(consignmentId:$consignmentId, total:$total, sourceId:$sourceId){
    consignment{
      id
      inventoryPtr{
        id
        total
      }
      source{
        id
        title
      }
    }
  }
}
`

const ConsignmentModal = ({consignment, orderItem, open, setOpen, sizeStr, setReturned, total}) => {
  const [delete_consignment] = useMutation(DELETE_CONSIGNMENT);
  const [update_consignment] = useMutation(UPDATE_CONSIGNMENT)
  const { data, loading } = useQuery(SOURCES);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [values, setValues] = useState({
    sourceId: consignment.source.id,
    total: total
  })

  if (loading) return <Loading/>

  const {sources} = data

  const handleChange = ({target}) => {
    setValues({...values, [target.name]: target.value})
  }

  const handleUpdate = () => {
    update_consignment({
      variables: { 
        ...values,
        consignmentId: consignment.id,
       }
    });
    setOpen(false);
  }

  const handleDelete = () => {
    delete_consignment({
      variables: { consignmentId: consignment.id }
    });
    setReturned(true);
    setOpen(false);
  }


  return (
  <Dialog open={open} onClose={() => setOpen(false)}>
    <DialogTitle>Size {sizeStr}</DialogTitle>
    <DialogContent>
      <Box p={2}>
        <TextField
            select
            name="sourceId"
            label="Source"
            value={values.sourceId}
            fullWidth
            variant="outlined"
            onChange={handleChange}
            disabled={orderItem}
          >
            {sources.map((source) => (
              <MenuItem key={source.id} value={source.id}>
                {source.title}
              </MenuItem>
            ))}
          </TextField>
          <Box m={2}/>
          <TextField 
            name="total"
            label="Requested Price" 
            value={values.total}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">KWD</InputAdornment>,
            }}
            />
      </Box>
    </DialogContent>
      <Box display={"flex"} justifyContent="space-between" p={1}>
        <Button
          onClick={()=>setOpenConfirmation(true)}
          disabled={orderItem}
          color="secondary"
        >
          Return
        </Button>
        <Button 
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Box>
      <ConfirmationModal
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        action={handleDelete}
      />
  </Dialog>
  )
}

export default ConsignmentModal