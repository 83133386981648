import {
  Button,
  Dialog,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import {
  ConfirmationModal,
  ErrorAlert
} from "../../common";
import {
  DELETE_PURCHASE,
  UPDATE_PURCHASE
} from "../../../Graphql/Mutations/Purchases";
import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { PURCHASES } from "../../../Graphql/Queries/Purchases";
// components
import PurchaseItemRow from "./PurchaseItemRow";
import { useMutation } from "@apollo/client";

const PurchaseRow = ({ purchase }) => {
  const foreignExchangeExpenses = 0
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [updatePurchase] = useMutation(UPDATE_PURCHASE);
  const [deletePurchase] = useMutation(DELETE_PURCHASE, {
    update: cache => {
      const existingPurchases = cache.readQuery({ query: PURCHASES });
      const newPurchases = existingPurchases.purchases.filter(
        p => p.id !== purchase.id
      );
      cache.writeQuery({
        query: PURCHASES,
        data: { purchases: newPurchases }
      });
    }
  });

  const setCostKwdOrNotes = async e => {
    e.preventDefault();
    try {
      const form = new FormData(e.target);
      const costKwd = form.get("costKwd");
      const notes = form.get("notes")
      await updatePurchase({
        variables: { purchaseId: purchase.id, costKwd, notes }
      });
      setOpen(false);
      setOpenNotes(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const setShippingCost = async e => {
    e.preventDefault();
    try {
      const form = new FormData(e.target);
      const shippingCost = form.get("shippingCost");
      await updatePurchase({
        variables: { purchaseId: purchase.id, shippingCost }
      });
      setOpen(false);
    } catch (error) {
      setError(error.message);
    }
  };


  const cancelPurchase = async e => {
    e.preventDefault();
    try {
      await deletePurchase({
        variables: {
          purchaseId: purchase.id,
          foreignExchangeExpenses
        }
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const isCancellable = !purchase.purchaseItems.some(
    purchaseItem => purchaseItem.status.title.toLowerCase() === "arrived"
  );

  const shippingTotal = purchase.purchaseItems.reduce((total, purchaseItem) => {
    const shipping = purchaseItem.shipping ? purchaseItem.shipping : 0;
    return total + shipping;
  }, 0)

  const purchaseItemRows = purchase.purchaseItems.map(purchaseItem => (
    <PurchaseItemRow
      key={purchaseItem.id}
      purchaseItem={purchaseItem}
      currency={purchase.currency}
    />
  ));

  return (
    <>
      <TableRow className="bg-light" hover>
        <TableCell colSpan={2} padding={isCancellable ? "none" : "default"}>
          <Typography>
            {isCancellable && (
              <IconButton onClick={() => setOpenConfirmation(true)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
            <b>
              {purchase.id}|{purchase.source.title} | {purchase.purchaseDate}
            </b>
            <IconButton onClick={() => setOpenNotes(true)} color={purchase.notes ? "secondary" : "primary"}>
              <EditNoteIcon/>
            </IconButton>
            {purchase.notes}
          </Typography>
        </TableCell>
        <TableCell>
          {isCancellable && purchase.costKwd ?  
          <form onSubmit={setShippingCost}>
            <TextField size="small" name="shippingCost" placeholder="Shipping"/>
            <Button size="small" color="secondary" type="submit">
              set
            </Button>
          </form>
          :
            `${shippingTotal} KWD`
          }

        </TableCell>
        <TableCell align="right">
          {purchase.cost + purchase.internalShipping} {purchase.currency}/
          {purchase.costKwd !== null ? (
            `${purchase.costKwd} KWD`
          ) : (
            <Button color="secondary" onClick={() => setOpen(true)}>
              set KWD
            </Button>
          )}
        </TableCell>
      </TableRow>
      {purchaseItemRows}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={setCostKwdOrNotes} className="p-5">
          <TextField label="Cost in KWD" name="costKwd" />
          <Button type="submit">Save</Button>
        </form>
      </Dialog>
      <Dialog open={openNotes} onClose={() => setOpenNotes(false)}>
        <Stack component="form" onSubmit={setCostKwdOrNotes} className="p-4" spacing={2}>
          <Typography variant="h5">Notes</Typography>
          <TextField name="notes" defaultValue={purchase.notes} multiline minRows={3}/>
          <Button type="submit" color="secondary" variant="contained">Save notes</Button>
        </Stack>
      </Dialog>

      <ErrorAlert error={error} handleClose={() => setError(null)} />  
      <ConfirmationModal
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        action={cancelPurchase}
      />
    </>
  );
};

export default PurchaseRow;
