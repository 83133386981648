import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";

import ItemRow from "../../../Orders/Details/Items/ItemRow";
import { Link } from "react-router-dom";
import React from "react";
import { withStyles } from "@mui/styles";

const GreyTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "rgb(249,250,251)"
  }
}))(TableCell);

const Order = ({ order }) => {
  const headers = ["Item", "", "Unit Price", "Subtotal"].map(
    (header, index) => (
      <GreyTableCell
        key={index}
        align={index > 1 ? "right" : "left"}
        style={{ fontSize: "15px" }}
      >
        {header}
      </GreyTableCell>
    )
  );
  const orderItems = order.orderItems.map((orderItem, index) => (
    <ItemRow key={index} item={orderItem} />
  ));
  return (
    <>
      <Button className="mt-5" component={Link} to={`/orders/${order.id}`}>
        <h5>For Order# {order.id}</h5>
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>{headers}</TableRow>
          </TableHead>
          <TableBody>{orderItems}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Order;
