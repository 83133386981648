import {
  TableCell,
  TableRow,
  Typography,
  useMediaQuery
} from "@mui/material";

import React from "react";
import { useTheme } from "@mui/material/styles";

const DateRow = ({ date, orders }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <TableRow className="bg-light">
      <TableCell colSpan={matches ? 2 : 5}>
        <Typography>{date}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{orders.length} orders</Typography>
      </TableCell>
    </TableRow>
  );
};

export default DateRow;
