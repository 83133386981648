import {
  Button,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material";
// components
import { ConfirmationModal, ErrorAlert } from "../../common";
import {
  DELETE_PURCHASE_ITEM,
  FLAG_PURCHASE_ITEM,
  UPDATE_PURCHASE_ITEM
} from "../../../Graphql/Mutations/Purchases";
import React, { useState } from "react";
// apollo
import { useMutation, useQuery } from "@apollo/client";

import DeleteIcon from "@mui/icons-material/Delete";
import FlagIcon from "@mui/icons-material/Flag";
import { STATUSES } from "../../../Graphql/Queries/Purchases";
import SkipNextIcon from "@mui/icons-material/SkipNext";

const PurchaseItemRow = ({ purchaseItem, currency }) => {
  const [error, setError] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { data } = useQuery(STATUSES, { fetchPolicy: "cache-only" });
  const [updatePurchaseItem] = useMutation(UPDATE_PURCHASE_ITEM);
  const [deletePurchaseItem] = useMutation(DELETE_PURCHASE_ITEM);
  const [flagPurchaseItem] = useMutation(FLAG_PURCHASE_ITEM);

  const nextStatus = data.statuses.find(
    status => status.sequence === purchaseItem.status.sequence + 1
  );

  const saveShipping = async e => {
    e.preventDefault();
    const form = new FormData(e.target);
    const shipping = form.get("shipping");

    try {
      await updatePurchaseItem({
        variables: { purchaseItemId: purchaseItem.id, shipping: shipping }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const saveStatus = async () => {
    try {
      await updatePurchaseItem({
        variables: { purchaseItemId: purchaseItem.id, statusId: nextStatus.id }
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const cancelPurchaseItem = async e => {
    e.preventDefault();
    try {
      await deletePurchaseItem({
        variables: { purchaseItemId: purchaseItem.id }
      });

    } catch (error) {
      setError(error.message);
    }
  };

  const flagPurchaseItemAction = async () => {
    try {
      await flagPurchaseItem({
        variables: {
          purchaseItemId: purchaseItem.id
        }
      });
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <TableRow
        style={{ backgroundColor: purchaseItem.isFlagged ? "#ffeeed" : "" }}
      >
        <TableCell>
          <p className="mb-0">{purchaseItem.variation.product.title}</p>
          <p className="small text-muted">
            {purchaseItem.variation.sizeStr.split("|")[0]}
          </p>
        </TableCell>
        <TableCell>
          <p style={{ color: `${purchaseItem.status.color}` }}>
            {purchaseItem.status.title}
            {nextStatus ? (
              <Tooltip title={nextStatus.title} arrow>
                <IconButton size="small" onClick={saveStatus}>
                  <SkipNextIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </p>
        </TableCell>
        <TableCell>
          {purchaseItem.shipping !== null ? (
            <>
              <p className="mb-0">{purchaseItem.shipping} KWD</p>
              <p className="text-muted small">Shipping</p>
            </>
          ) : (
            <form onSubmit={saveShipping}>
              <TextField
                name="shipping"
                label="shipping"
                size="small"
                variant="outlined"
                disabled={purchaseItem.costKwd === null}
              />
              <br />
              <Button
                size="small"
                type="submit"
                disabled={purchaseItem.costKwd === null}
              >
                Save
              </Button>
            </form>
          )}
        </TableCell>
        <TableCell align="right">
          <p className="mb-0">
            {" "}
            {purchaseItem.cost} {currency}
          </p>
          <p className="small text-muted">
            {purchaseItem.costKwd !== null ? `${purchaseItem.costKwd} KWD` : null}
          </p>
          <IconButton onClick={() => setOpenConfirmation(true)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={flagPurchaseItemAction}>
            <FlagIcon fontSize="small" className="text-danger" />
          </IconButton>
        </TableCell>
      </TableRow>

      <ErrorAlert error={error} handleClose={() => setError(null)} />
      <ConfirmationModal 
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        action={cancelPurchaseItem}
      />
    </>
  );
};

export default PurchaseItemRow;
