import { Button, useMediaQuery } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import moment from "moment-timezone";
import { useTheme } from "@mui/material/styles";

const DateSearch = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  refetch
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <DatePicker
        className={`mt-4 mt-md-0 ${isMobile ? "w-50" : ""}`}
        label="Start Date"
        name="startDate"
        variant="inline"
        format="D MMM YYYY"
        inputVariant="outlined"
        size="small"
        allowKeyboardControl
        autoOk
        disableFuture
        value={startDate}
        onChange={setStartDate}
      />
      <DatePicker
        className={`mt-4 mt-md-0 ${isMobile ? "w-50" : ""}`}
        label="End Date"
        name="endDate"
        variant="inline"
        inputVariant="outlined"
        format="D MMM YYYY"
        size="small"
        autoOk
        disableFuture
        value={endDate}
        onChange={setEndDate}
      />
      <Button
        className="mx-1 mt-md-0 mt-2"
        color="primary"
        variant="contained"
        fullWidth={isMobile}
        onClick={() =>
          refetch({
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD")
          })
        }
      >
        Retrieve Orders
      </Button>
    </>
  );
};

export default DateSearch;
