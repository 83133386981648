import { Box, Grid } from "@mui/material";
import {
  CREATE_CONSIGNMENT,
  CREATE_PURCHASE
} from "../../../Graphql/Mutations/Purchases";
import React, { useState } from "react";

import ProductSearch from "./ProductSearch";
import ProductsTable from "./ProductsTable";
import Summary from "./Summary";
import { updatePurchasesCache } from "../../../Graphql/Helpers";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

const Create = () => {
  const history = useHistory();
  const [addedProducts, setAddedProducts] = useState([]);
  const [isConsignment, setIsConsignment] = useState(false);

  const [createPurchase] = useMutation(CREATE_PURCHASE, {
    update: updatePurchasesCache,
    onCompleted: () => history.push(`/purchases/create/`)
  });

  const [createConsignment] = useMutation(CREATE_CONSIGNMENT, {
    // update: updatePurchasesCache,
    onCompleted: () => history.push(`/purchases/create/`)
  });

  const total = addedProducts.reduce(
    (total, product) => total + product.cost * product.quantity,
    0
  );

  const addProduct = product => {
    product.quantity = 1;
    product.selectedVariation = 0;
    product.cost = 0;
    setAddedProducts(products => [...products, product]);
  };

  const removeProduct = index => {
    let newProducts = [...addedProducts];
    newProducts.splice(index, 1);
    setAddedProducts(newProducts);
  };

  const handleChange = (productIndex, name, value) => {
    let newProducts = [...addedProducts];
    let product = {
      ...newProducts[productIndex]
    };
    product[name] = value;
    newProducts[productIndex] = product;
    setAddedProducts([...newProducts]);
  };

  const setCreatePurchaseInput = async (inputDictionary, isConsignment) => {
    let input = {};
    const purchaseItems = addedProducts.map(product => {
      return {
        variationId: product.variations[product.selectedVariation].id,
        cost: product.cost,
        quantity: product.quantity
      };
    });
    if (isConsignment) {
      input = {
        ...inputDictionary,
        consignmentItems: purchaseItems
      };
    } else {
      input = {
        ...inputDictionary,
        cost: total,
        purchaseItems: purchaseItems
      };
    }

    try {
      if (isConsignment) await createConsignment({ variables: input });
      else await createPurchase({ variables: input });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box pt={3}>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ProductSearch addProduct={addProduct} />
          <ProductsTable
            products={addedProducts}
            handleChange={handleChange}
            removeProduct={removeProduct}
            isConsignment={isConsignment}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Summary
            total={total}
            createAction={setCreatePurchaseInput}
            clearProducts={() => setAddedProducts([])}
            setIsConsignment={setIsConsignment}
            isConsignment={isConsignment}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Create;
