import {
  Checkbox,
  Hidden,
  IconButton,
  Input,
  MenuItem,
  Select,
  TableCell,
  TableRow
} from "@mui/material";
import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";

const ProductRow = ({
  product,
  index,
  handleVariationChange,
  handleQuantityChange,
  handleGiftWrapChange,
  removeProduct
}) => {
  const [variation, setSelectedVariation] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [isGiftWrapped, setIsGiftWrapped] = useState(false);
  
  // available sizes for the size dropdown
  const sizes = product.variations.map((variation, index) => {
    return (
      <MenuItem value={index} key={index} disabled={!variation.inStock}>
        {variation.sizeStr}
      </MenuItem>
    );
  });

  

  // available quantity for the selcted variation(size)
  const selectedVariation = product.variations.length === 1 ? 0 : variation;
  const availableQuantity = selectedVariation !== "" ? product.variations[selectedVariation].inventory : 0;


  return (
    <TableRow>
      <Hidden xsDown>
        <TableCell padding="checkbox">
          <img src={product.image} width="70px" alt={product.title} />
        </TableCell>
      </Hidden>
      <TableCell>{product.title}</TableCell>
      <TableCell padding="checkbox">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedVariation}
          onChange={e => {
            setSelectedVariation(e.target.value);
            setQuantity(1);
            handleVariationChange(index, e.target.value);
          }}
        >
          {sizes}
        </Select>
      </TableCell>
      <TableCell style={{ minWidth: "70px" }}>
        <Input
          label="Quantity"
          variant="outlined"
          value={quantity}
          type="number"
          fullWidth
          inputProps={{ min: 1, max: availableQuantity }}
          onChange={e => {
            const quantity =
              e.target.value > availableQuantity
                ? availableQuantity
                : e.target.value;
            setQuantity(quantity);
            handleQuantityChange(index, quantity);
          }}
        />
      </TableCell>
      <TableCell>
        {selectedVariation !== "" ?
          product.variations[selectedVariation].salePrice
            ? product.variations[selectedVariation].salePrice * quantity
            : product.variations[selectedVariation].price * quantity
          : 0
        }{" "}
        KD
      </TableCell>
      <TableCell padding="none" align="center">
        <Checkbox
          label="gift"
          checked={isGiftWrapped}
          onChange={e => {
            setIsGiftWrapped(e.target.checked);
            handleGiftWrapChange(index, e.target.checked);
          }}
          size="small"
          className="p-0"
        />
      </TableCell>
      <TableCell align="right" padding="none">
        <IconButton
          aria-label="delete"
          onClick={() => removeProduct(index)}
          className="p-1 w-1"
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ProductRow;
