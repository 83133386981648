import { TableCell, TableRow } from "@mui/material";

import React from "react";

const ReturnTable = ({ item }) => {
  return (
    <TableRow className="text-nowrap">
      <TableCell padding="checkbox">
        <img
          src={item.variation.product.image}
          height="45px"
          className="ml-2 my-2"
          alt={item.variation.product.title}
        />
      </TableCell>
      <TableCell>
        <p className="mb-0">{item.variation.product.title}</p>
        <p className="mb-0 small text-muted">{item.variation.sizeStr}</p>
      </TableCell>
      <TableCell align="right">
        {item.quantity}X{item.price}
      </TableCell>
      <TableCell align="right">{item.total}</TableCell>
    </TableRow>
  );
};

export default ReturnTable;
