import { Box, Table, TableBody, TableContainer } from "@mui/material/";

import ProductRow from "./ProductRow";
import React from "react";

const OrderItems = ({ orderItems, setOrderItems, order }) => {
  const handleQuantityChange = (productIndex, quantity) => {
    let newProducts = [...orderItems];
    const product = {
      ...newProducts[productIndex],
      quantity: quantity
    };
    newProducts[productIndex] = product;
    setOrderItems([...newProducts]);
  };

  const productRows = orderItems.map((product, index) =>
    product.quantity === 0 ? null : (
      <ProductRow
        product={product}
        index={index}
        handleVariationChange={() => {}}
        handleQuantityChange={handleQuantityChange}
        removeProduct={() => handleQuantityChange(index, 0)}
        inventory={order.orderItems[index].quantity}
        key={index}
      />
    )
  );

  return (
    <TableContainer
      component={Box}
      mt={3}
      p={2}
      border={1}
      borderColor="grey.300"
    >
      <Table>
        {orderItems.length === 0 ? <caption>No orders added</caption> : null}
        <TableBody>{productRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderItems;
