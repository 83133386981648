import { Autocomplete, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import gql from "graphql-tag";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const PRODUCTS = gql`
  query($search:String){
    products(search:$search) {
      id
      title
      productType {
        id
        title
      }
    }
  }
`

const FORM_DATA = gql`
  query{
    productTypes{
      id
      sizes{
        id
        title
      }
    }
    sizes{
      id
      title
    }
    currencies {
      name
    }
  }
`

const CREATE_CUSTOM_ORDER = gql`
mutation($productId: Int, $productName: String, $customerName: String!, $sizeId:Int, $price: Float, $cost: Float, $currency:String, $phoneNumber: String!){
  createCustomOrder(
    productId: $productId, 
    customerName: $customerName,
    productName: $productName,
    sizeId:$sizeId, 
    price: $price, 
    cost: $cost, 
    currency:$currency
    phoneNumber:$phoneNumber
  ){
    order{
      id
    }
  }
}`

const ProductCustomItem = () => {
  const history = useHistory();
  const [values, setValues] = useState({
    phoneNumber: undefined,
    product: undefined,
    productName: undefined,
    customerName: undefined,
    size: null,
    price: 10,
    currency: null,
    cost: 10
  })

  const [createCustomOrder] = useMutation(CREATE_CUSTOM_ORDER, {
    onCompleted: async data => history.push(`/orders/${data.createCustomOrder.order.id}`)
  });
  const [getProducts, { data: productsData, loading: productsLoading }] = useLazyQuery(PRODUCTS, {
    variables: {
      variables: {
        active: true,
        new:true
      }
    },
    fetchPolicy: "cache-and-network"
  });
  const { data, loading } = useQuery(FORM_DATA)

  let products = productsData?.products ?? []
  const productType = values.product?.id ? data?.productTypes?.find((productType) => productType.id === values.product.productType.id) : null
  const sizes = productType ? productType.sizes.length !== 0 ? productType.sizes : data?.sizes : data?.sizes
  const currencies = data?.currencies ?? [];

  const handleTextChange = ({target}) => {
    if (target.value.length === 3) getProducts({variables:{search:target.value}})
  }

  const handleChange = ({target}) => {
    setValues({...values, [target.name]:target.value})
  }

  const handleAutocompleteChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setValues({...values, product:{title:newValue} });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValues({...values, product:{title:newValue.inputValue}});
    } else {
      setValues({...values, product: newValue});
    }
  }

  const filterOptions = (options, params) => {
    const { inputValue } = params;
    const filtered = options.filter((option) => option.title.toLowerCase().includes(inputValue))
    
    const isExisting = options.some((option) => option.title.toLowerCase().includes(inputValue));
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        title: `Add "${inputValue}"`,
      });
    }
    return filtered;
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const variables = {
      phoneNumber: values.phoneNumber,
      customerName: values.customerName,
      productId: values.product.id ?? null,
      productName: values.product.title,
      sizeId: values.size.id,
      price: values.price,
      currency: values.currency,
      cost: values.cost,
    }
    createCustomOrder({variables})
  }

  return (
    <Stack spacing={3} component={"form"} onSubmit={handleSubmit}>
      <Autocomplete
        options={products}
        getOptionLabel={products => products.title}
        loading={productsLoading}
        onChange={handleAutocompleteChange}
        openOnFocus
        filterOptions={filterOptions}
        value={values.product}
        renderInput={params => (
          <TextField
            {...params}
            label="Product"
            variant="outlined"
            color="primary"
            onChange={handleTextChange}
          />
        )}
      />
      <TextField label="Customer Name" name="customerName" value={values.name} onChange={handleChange}/>
      <TextField required label="Phone Number" name="phoneNumber" value={values.phoneNumber} onChange={handleChange}/>
      <Autocomplete
        options={sizes ? sizes : []}
        getOptionLabel={sizes => sizes.title}
        loading={loading}
        onChange={(e, size) => setValues({...values, size})}
        openOnFocus
        value={values.size}
        renderOption={(props, option)=><li {...props} key={option.id}>{option.title}</li>}
        renderInput={params => (
          <TextField
            {...params}
            label="Size"
            variant="outlined"
            color="primary"
          />
        )}
      />
      <TextField label="Price" name="price" value={values.price} onChange={handleChange}/>
      <TextField label="Cost" name="cost" value={values.cost} onChange={handleChange}/>
      <FormControl component="fieldset" className="d-block mt-3">
        <FormLabel component="legend">Currency</FormLabel>
        <RadioGroup
          name="currency"
          className="d-block"
          onChange={handleChange}
        >
          {currencies.map(currency => (
            <FormControlLabel
              key={currency.name}
              value={currency.name}
              control={<Radio />}
              label={currency.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Button variant="contained" color="secondary" type="submit">Create</Button>
    </Stack>    
  )
}

export default ProductCustomItem