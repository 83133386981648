import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery
} from "@mui/material";
import React, { Fragment, useState } from "react";

import DateRow from "./DateRow";
import { INITIAL_QUERY } from "../../../Graphql/Queries/Orders";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OrderRow from "./OrderRow";
import { useApolloClient } from "@apollo/client";
import { useTheme } from "@mui/material/styles";

const OrderTable = ({ orders }) => {
  const [rowsOpen, setRowsOpen] = useState([]);
  const client = useApolloClient();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const { orderStatuses: statuses } = client.readQuery({
    query: INITIAL_QUERY
  });

  const handleOpen = (orderId, open) => {
    let newRowsOpen = [...rowsOpen];
    if (open) {
      newRowsOpen.push(orderId);
    } else {
      newRowsOpen.splice(newRowsOpen.indexOf(orderId), 1);
    }
    setRowsOpen(newRowsOpen);
  };

  const dates = [...new Set(orders.map(order => order.createdDate))];

  const DateRows = dates.map(date => {
    const dateOrders = orders.filter(order => order.createdDate === date);
    return (
      <Fragment key={date}>
        <DateRow date={date} orders={dateOrders} />
        {dateOrders.map((order, index) => (
          <OrderRow
            key={order.id}
            order={order}
            statuses={statuses}
            open={rowsOpen.find(row => row === order.id) ? true : false}
            setOpen={handleOpen}
            index={index}
          />
        ))}
      </Fragment>
    );
  });

  const headers = matches
    ? ["Order#", "Order Status", "Payment"]
    : ["", "Order#", "Country", "Order Status", "Order Source", "Payment"];

  return (
    <div className="mt-5">
      <Button
        variant="contained"
        color="secondary"
        className="mx-1 pl-2"
        onClick={() => setRowsOpen(orders.map(order => order.id))}
      >
        <KeyboardArrowDownIcon className="mr-1" /> Open All
      </Button>
      <Button
        variant="contained"
        color="primary"
        className="mx-1 pl-2"
        onClick={() => setRowsOpen([])}
      >
        <KeyboardArrowUpIcon className="mr-1" />
        Close All
      </Button>

      <TableContainer component={Paper} className="mt-3">
        <Table size="small">
          <caption>{orders.filter(({status})=>status.title !== "Canceled").length} orders</caption>
          <TableHead>
            <TableRow className="bg-light">
              {headers.map(header => (
                <TableCell size="medium" key={header}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{DateRows}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OrderTable;
