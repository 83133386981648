import { SHIPPING_FEES_OPTIONS, paymentStatus } from "../../../../constants";

import { Button } from "@mui/material";
import React from "react";
// apollo
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const EDIT_RETURN_REQUEST = gql`
  mutation($returnRequestId: Int!, $paymentStatus: String!) {
    editReturnRequest(
      returnRequestId: $returnRequestId
      paymentStatus: $paymentStatus
    ) {
      returnRequest {
        id
        paymentStatus
      }
    }
  }
`;

const ReturnOptions = ({ returnRequest }) => {
  const [editReturnRequest] = useMutation(EDIT_RETURN_REQUEST);
  const input = {
    returnRequestId: returnRequest.id,
    paymentStatus: paymentStatus[Object.keys(paymentStatus)[1]]
  };

  return (
    <>
      <p>
        <b>Created By:</b> {`${returnRequest.createdBy?.firstName} ${returnRequest.createdBy?.lastName}`}
      </p>
      <p>
        <b>Delivery Type:</b> {returnRequest.isPickup ? "pick up" : "delivery"}
      </p>
      {!returnRequest.isPickup && (
        <p>
          <b>Shipping Option: </b>
          {SHIPPING_FEES_OPTIONS[returnRequest.shippingOption]}
        </p>
      )}
      {returnRequest.shippingOption === "INCLUDE" && (
        <p>
          <b>Shipping: </b>
          {returnRequest.address
            ? `${returnRequest.address.city.shipping} KWD`
            : "free shipping"}
        </p>
      )}
      <p>
        <b>Due Amount:</b>{" "}
        <span
          className={
            returnRequest.dueAmount > 0 ? "text-success" : "text-danger"
          }
        >
          {returnRequest.dueAmount} KWD
        </span>
      </p>
      {returnRequest.paymentStatus === Object.keys(paymentStatus)[0] && (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => editReturnRequest({ variables: input })}
        >
          Set as paid
        </Button>
      )}
    </>
  );
};

export default ReturnOptions;
