import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React from "react";
import VariantionsTable from "./VariantionsTable";

const ProductTable = ({ product }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow hover onClick={() => setOpen(!open)}>
        <TableCell padding="checkbox" className="bg-light w-1">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={8} className="bg-light font-weight-bold">
          <p className="mb-0">{product.title}</p>
          <p className="small text-muted mb-0">{product.brand.title}</p>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="pt-0 pb-0" colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <VariantionsTable productId={product.id} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProductTable;
