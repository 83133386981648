import { Link, useParams } from "react-router-dom";

import { Button } from "@mui/material";
import Error from "./Error";
import Footer from "./Footer";
import GiftCard from "./GiftCard";
import Header from "./Header";
// components
import ItemsTable from "./Items/ItemsTable";
import Loading from "../../common/Loading";
// graphql
import { ORDER } from "../../../Graphql/Queries/Orders";
import React from "react";
import ReturnRequests from "./ReturnRequests.js";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";

const Details = () => {
  let { orderId } = useParams();

  const { data, loading, error } = useQuery(ORDER, {
    variables: { id: orderId },
    fetchPolicy: "cache-first"
  });
  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  let order = data.order;

  if (moment(order.createdDate, moment.ISO_8601).isValid())
    order = {...order, createdDate: moment(order.createdDate).tz("Asia/Kuwait").format("D MMM YYYY HH:mm")};
  
  return (
    <div className="mx-md-5 mx-1 mb-5">
      <Link to="/orders" className="ml-md-n5 ml-0">
        <Button color="secondary">Back to orders</Button>
      </Link>
      <Header order={order} />
      <ItemsTable order={order} />
      {order.isGiftWrapped && order.gift && <GiftCard {...order.gift} />}
      <ReturnRequests returnRequests={order.returnRequests} />
      <Footer order={order} />
    </div>
  );
};

export default Details;
