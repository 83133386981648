import { Autocomplete, Box, Table, TableBody, TableContainer, TextField } from "@mui/material";
import React, { useState } from "react";

import ErrorMessage from "../../common/ErrorMessage";
import Loading from "../../common/Loading";
import ProductRow from "./ProductRow";
import gql from "graphql-tag";
// apollo
import { useQuery } from "@apollo/client";

export const PRODUCTS = gql`
  query($productTypeId: Int, $sizes: [Int]) {
    priorityProducts(productType: $productTypeId, sizes: $sizes) {
      id
      title
      brand {
        title
      }
    }
    sizes(productType: $productTypeId){
      id
      title
    }
  }
`;

const InventoryTable = ({ productTypeId }) => {
  const { loading, data, error, refetch } = useQuery(PRODUCTS, {
    variables: { productTypeId }
  });
  const [search, setSearch] = useState("");
  const [size, setSize] = useState(undefined);

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  const { priorityProducts :products, sizes } = data;

  const handleChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const handleSizeRefetching = (e, size) => {
    setSize(size)
    if (size === null) refetch({productTypeId})
    else refetch({productTypeId, sizes: size.map(size => size.id)})

  }

  const productRows = products.filter(product => product.title.toLowerCase().includes(search.trim()) || product.brand.title.toLowerCase().includes(search.trim())).map((product, idx) => (
    <ProductRow key={idx} {...product} />
  ));

  return (
    <TableContainer component={Box} height="85vh">
      <TextField value={search} onChange={handleChange} placeholder="Search" size="small" fullWidth variant="outlined"/>
      <Autocomplete
        multiple
        options={sizes}
        getOptionLabel={sizes => sizes.title}
        loading={loading}
        onChange={handleSizeRefetching}
        openOnFocus
        value={size}
        renderOption={(props, option)=><li {...props} key={option.id}>{option.title}</li>}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Size"
            variant="outlined"
            color="primary"
            size="small"
            fullWidth
          />
        )}
      />
      <Table size="small">
        <TableBody>{productRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default InventoryTable;
