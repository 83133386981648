import Alert from "@mui/lab/Alert";
import React from "react";
import { Snackbar } from "@mui/material";

const ErrorAlert = ({ error, handleClose }) => {
  return (
    <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        {error}
      </Alert>
    </Snackbar>
  );
};

export default ErrorAlert;
