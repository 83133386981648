import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import React, { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VariationsTable from "./VariationsTable";

const ProductRow = ({ id, title, brand }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow onClick={() => setOpen(prev => !prev)}>
        <TableCell padding="checkbox" className="bg-light w-1">
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={3} className="bg-light">
          <Typography variant="body1">{title}</Typography>
          <p className="small text-muted mb-0">{brand.title}</p>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="pt-0 pb-0" colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} width="fit-content">
              <VariationsTable productId={id} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProductRow;
