import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { TreeItem, TreeView } from '@mui/x-tree-view';

import AddressForm from "./AddressForm";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  treeItem: {
    expanded: {
      color: theme.palette.secondary.main
    },
    label: {
      fontWeight: "bold"
    }
  }
}));

const ContactsList = ({ contacts, setShipping, deliveryType }) => {
  const classes = useStyles();
  const [newAdress, setNewAdress] = useState(false);
  const [selectedContact, setSelectedContact] = useState(0);

  const addNewAddress = contactIndex => {
    setSelectedContact(contactIndex);
    setNewAdress(true);
  };

  const selectAddress = (index, address) => {
    setSelectedContact(index);
    setShipping(address.city.shipping);
  };

  if (contacts.length === 0)
    return (
      <>
        <h6 className="ml-3 text-muted">No Contacts</h6>
      </>
    );

  const ContactRadios = contacts.map((contact, index) => {
    return (
      <TreeItem
        nodeId={`${index}`}
        label={`${contact.firstName} ${contact.lastName} - ${contact.username}`}
        key={contact.id}
        className={classes.treeItem}
        onClick={() => {
          if (deliveryType === "Pickup") setSelectedContact(index);
        }}
      >
        {deliveryType === "Pickup" ? null : (
          <>
            <Button
              onClick={() => addNewAddress(index)}
              className="mt-2"
              color="secondary"
            >
              New Address
            </Button>
            {contact.addresses.map(address => (
              <div
                className="form-check my-3 ml-3"
                key={address.id}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="addressId"
                  value={address.id}
                  onChange={() => selectAddress(index, address)}
                />
                <label className="form-check-label">
                  {address.phoneNumber} <br />
                  {address.address}
                  {address.address2 ? (
                    <>
                      <br />
                      {address.address2}
                    </>
                  ) : null}
                  <br />
                  {address.city.name}, {address.country.name}
                </label>
              </div>
            ))}
          </>
        )}
      </TreeItem>
    );
  });

  return (
    <>
      <input
        name="contact"
        readOnly
        hidden
        value={contacts[selectedContact].id}
      />
      <input
        hidden
        readOnly
        value={contacts[selectedContact].username}
        name="username"
      />
      <input
        hidden
        readOnly
        value={contacts[selectedContact].firstName}
        name="firstName"
      />
      <input
        hidden
        readOnly
        value={contacts[selectedContact].lastName}
        name="lastName"
      />
      {newAdress ? (
        <>
          <Button onClick={() => setNewAdress(false)} color="secondary">
            Back to addresses
          </Button>
          <Typography variant="h6">
            {contacts[selectedContact].username} -{" "}
            {contacts[selectedContact].firstName}{" "}
            {contacts[selectedContact].lastName}
          </Typography>
          <AddressForm setShipping={setShipping} deliveryType={deliveryType} />
        </>
      ) : (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          className="ml-3"
        >
          {ContactRadios}
        </TreeView>
      )}
    </>
  );
};

export default ContactsList;
