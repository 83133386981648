import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// apollo
import { useMutation, useQuery } from "@apollo/client";

import { CREATE_RETURN_REQUEST } from "../../../Graphql/Mutations/Orders";
import Loading from "../../common/Loading";
import { MIN_ORDER } from "../../../Graphql/Queries/Orders";
import OrderItems from "./Products/OrderItems";
import ProductSearch from "./Products/Search";
// components
import ProductsTable from "./Products/ProductsTable";
import Summary from "./Summary";

const Return = () => {
  const COMPLETED = "delivered";
  const history = useHistory();
  let { orderId } = useParams();

  const [exchangeItems, setExchangeItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);

  const [returnItems] = useMutation(CREATE_RETURN_REQUEST, {
    onCompleted: data =>
      history.push(`/returns/${data.createReturnRequest.returnRequest.id}`)
  });

  const { data, loading } = useQuery(MIN_ORDER, {
    variables: {
      id: orderId
    },
    onCompleted: data => {
      let orderItems = data.order.orderItems;
      orderItems = orderItems.map(product => ({...product, variations: [product.variation], selectedVariation:0}))
      setOrderItems(orderItems);
    },
    fetchPolicy: "cache-and-network"
  });

  if (loading) return <Loading />;

  const createReturnExchange = async address => {
    const returnedItems = orderItems.map((orderItem, index) => {
      return {
        orderItemId: orderItem.id,
        quantity: order.orderItems[index].quantity - orderItem.quantity
      };
    });

    const newItems = exchangeItems.map(exchangeItem => {
      return {
        variationId: exchangeItem.variations[exchangeItem.selectedVariation].id,
        quantity: exchangeItem.quantity
      };
    });

    const input = {
      orderId: order.id,
      orderItems: newItems,
      returnItems: returnedItems
    };

    if (address) input.address = address;

    try {
      await returnItems({ variables: input });
    } catch (error) {
      console.log(error);
    }
  };

  const addProduct = (product, defaultVariation) => {
    const newProduct = {...product, quantity: 1, selectedVariation: defaultVariation}
    setExchangeItems(products => [...products, newProduct]);
  };

  let total = orderItems.reduce(
    (total, product) => product.price * product.quantity + total,
    0
  );
  total = exchangeItems.reduce(
    (total, product) =>
      product.variations[product.selectedVariation].salePrice
        ? total +
          product.variations[product.selectedVariation].salePrice *
            product.quantity
        : total +
          product.variations[product.selectedVariation].price *
            product.quantity,
    total
  );

  const order = data.order;

  if (order.status.title.toLowerCase() !== COMPLETED)
    history.replace(`/orders/${order.id}`);

  return (
    <Box pt={3}>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography>Original Order</Typography>
          <OrderItems
            orderItems={orderItems}
            setOrderItems={setOrderItems}
            order={order}
          />
          <Typography className="my-4">Exchange</Typography>
          <ProductSearch addProduct={addProduct} />
          <ProductsTable
            products={exchangeItems}
            setProducts={setExchangeItems}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Summary
            total={total}
            order={order}
            createAction={createReturnExchange}
            clearProducts={() => setOrderItems([])}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Return;
