import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

const CreateButton = ({ url, text }) => {
  return (
    <Link to={url}>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        className="float-right ml-md-0 ml-5"
      >
        {text}
      </Button>
    </Link>
  );
};

export default CreateButton;
