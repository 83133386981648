import { Box, Toolbar } from "@mui/material";
import { Redirect, Switch } from "react-router-dom";

import AuthRoutes from "./navigation/AuthRoutes";
import Loading from "./components/common/Loading";
// components
import Nav from "./components/Nav";
import React from "react";
import Routes from "./navigation/Routes";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from '@mui/material/styles'
import useAuth from "./authentication";

const theme = createTheme({
  palette: {
    secondary: {
      light: "#ffccbc",
      main: "#ff5722",
      dark: "#ff3d00",
      contrastText: "#ffffff"
    },
    primary: {
      light: "#4d4d4d",
      main: "#212121",
      dark: "#171717",
      contrastText: "#ffffff"
    }
  }
});


function App() {
  const { isAuthenticated, loading, user } = useAuth();

  if (loading) return <Loading />;

  return (
    <ThemeProvider theme={theme}>
      <div className="d-lg-flex">
        {isAuthenticated ? <Nav /> : null}
        <Box p={3} flexGrow={1}>
          <Toolbar />
          <Switch>
            {isAuthenticated ? <AuthRoutes user={user} /> : <Routes />}

            <Redirect to="/login" />
          </Switch>
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
