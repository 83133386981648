import { Box, Table, TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
// components
import { DateSearch, ErrorMessage, Loading } from "../../common";
import React, { useState } from "react";

import CashierInfo from "./CahierInfo";
import gql from "graphql-tag";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";

const REPORT = gql`
  query($startDate: String, $endDate: String) {
    cashiers{
      id
      firstName
      lastName
      report(startDate: $startDate, endDate:$endDate){
        orderCount
        revenue
      }
    }
  }
`;

const EmployeeReport = () => {
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const { data, loading, error, refetch } = useQuery(REPORT, {
    variables: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    }
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  const { cashiers } = data;

  const headers = ["Employee", "Orders", "Revenue"];
  const headerCells = headers.map(header => <TableCell style={{fontSize:15}}>{header}</TableCell>);
  const rows = cashiers.map(cashier => <CashierInfo key={cashier.id} {...cashier}/>)

  return (
    <>
      <DateSearch
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        refetch={refetch}
      />
      <TableContainer component={Box} boxShadow={3} marginTop={3}>
        <Table >
          <TableHead>{headerCells}</TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
      
    </>
  );
};

export default EmployeeReport;
