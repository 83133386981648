import { font, fontBold, tableFont } from "./fonts";

/* global jsPDF */
import { PaymentMethod } from "../../../../constants";
import { image } from "./image";

const truncate = str => {
  const maxLength = 50;
  return str.length <= maxLength ? str : str.substring(0, maxLength) + "..";
};

const generatePDF = order => {
  var doc = new jsPDF("p", "pt");

  doc.addFileToVFS("Amiri-Regular.ttf", font);
  doc.addFileToVFS("Amiri-Bold.ttf", fontBold);
  doc.addFileToVFS("NotoSerif-Bold.ttf", tableFont);
  doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
  doc.addFont("Amiri-Bold.ttf", "Amiri", "bold");
  doc.addFont("NotoSerif-Bold.ttf", "NotoSerif", "bold");
  doc.setFont("Amiri");

  // logo
  doc.addImage(image, "PNG", 20, 50, 214, 25);
  doc.setTextColor("#333333");
  doc.setFontSize(30);
  doc.text(450, 70, "Invoice");

  // soled info
  doc.setTextColor("#000000");
  doc.setFontSize(11);
  doc.setFontType("bold");
  doc.text(30, 130, "AXL Goods");
  doc.text(30, 145, "Instagram:");
  doc.text(30, 160, "Whatsapp:");
  doc.setFontType("normal");
  doc.text(85, 145, "@axlgoods");
  doc.text(83, 160, "+965 69963331");
  doc.text(30, 175, "https://axlgoods.com");

  //   Invoice info
  doc.setFontType("bold");
  doc.text(420, 130, "Date:");
  doc.text(420, 145, "Invoice No.:");
  doc.text(420, 160, "Payment Method:");
  doc.setFontType("normal");
  doc.text(447, 130, order.createdDate);
  doc.text(480, 145, order.id);
  const paymentMethod = PaymentMethod[order.paymentMethod] === PaymentMethod.ONLINE
    ? order.onlinePaymentMethod
      ? order.onlinePaymentMethod
      : "Online"
    : PaymentMethod[order.paymentMethod];
  doc.text(508, 160, paymentMethod);

  //   seperator
  doc.setDrawColor("#cccccc");
  doc.line(30, 190, 560, 190);

  //   Shipping
  doc.setFontSize(13);
  doc.setFontType("bold");
  doc.text(30, 210, "Ship To");

  let y = 225;

  doc.setFontSize(11);
  if (order.isPickup) {
    doc.text(35, y, "Name:");
    doc.setFontType("normal");
    doc.text(68, y, `${order.user.firstName} ${order.user.lastName}`);

    y = y + 15;
    doc.setFontType("bold");
    doc.text(35, y, "Phone Number:");

    doc.setFontType("normal");
    doc.text(113, y, order.user.username);
  } else {
    doc.text(35, y, "Name:");
    doc.setFontType("normal");
    doc.text(
      68,
      y,
      `${order.address &&
        `${order.address.firstName} ${order.address.lastName}`}`
    );

    y = y + 15;
    doc.setFontType("bold");
    doc.text(35, y, "Phone Number:");

    doc.setFontType("normal");
    doc.text(113, y, `${order.address && order.address.phoneNumber}`);

    y = y + 15;
    doc.setFontType("bold");
    doc.text(35, y, "Email:");
    doc.setFontType("normal");
    doc.text(68, y, `${order.user.email ? order.user.email : "N/A"}`);

    y = y + 15;
    doc.setFontType("bold");
    doc.text(35, y, "Address:");

    doc.setFontType("normal");
    doc.text(
      80,
      y,
      `${order.address && order.address.country.name}, ${order.address &&
        order.address.city &&
        order.address.city.name}`
    );
    y = y + 15;
    doc.text(35, y, `${order.address && order.address.address}`);
    y = y + 15;
    doc.text(
      35,
      y,
      `${order.address && order.address.address2 ? order.address.address2 : ""}`
    );
  }

  // table
  doc.printingHeaderRow = true;
  doc.setFillColor(249, 250, 251);
  doc.setDrawColor(128, 128, 128);
  doc.setFontSize(8);
  doc.setFontType("bold");
  doc.setFont("NotoSerif");

  const productWidth = 220;
  const sizeWidth = 65;
  const quantityWidth = 50;
  const unitPriceWidth = 100;
  const subtotalWidth = 100;

  const productX = 32;
  const sizeX = productWidth + productX;
  const quantityX = sizeWidth + sizeX;
  const unitPriceX = quantityWidth + quantityX;
  const subtotalX = unitPriceWidth + unitPriceX;

  y = y + 20;
  let textStartY = y + 2;

  // table header
  doc.rect(30, y, 540, 20, "F");
  doc.rect(30, y, 540, 20);
  doc.setDrawColor(249, 250, 251);
  doc.cell(productX, textStartY, productWidth, 13, "");
  doc.cell(productX, textStartY, productWidth, 13, "Product");
  doc.setFillColor(249, 250, 251);
  doc.cell(sizeX, textStartY, sizeWidth, 13, "Size");
  doc.setFillColor(249, 250, 251);
  doc.cell(quantityX, textStartY, quantityWidth, 13, "Quantity", null, "right");
  doc.setFillColor(249, 250, 251);
  doc.cell(
    unitPriceX,
    textStartY,
    unitPriceWidth,
    13,
    "Unit Price",
    null,
    "right"
  );
  doc.setFillColor(249, 250, 251);
  doc.cell(subtotalX, textStartY, subtotalWidth, 13, "Subtotal", null, "right");

  // products
  doc.printingHeaderRow = false;
  y = y + 20;
  order.orderItems.forEach(orderItem => {
    textStartY = y + 2;
    doc.setDrawColor(128, 128, 128);
    doc.rect(30, y, 540, 27);
    doc.setDrawColor("#ffffff");
    doc.cell(productX, textStartY, productWidth, 13, "");
    doc.cell(
      productX,
      textStartY,
      productWidth,
      13,
      truncate(orderItem.variation.product.title)
    );
    doc.setFontSize(7);
    doc.setFontType("normal");
    doc.cell(
      productX,
      textStartY + 10,
      productWidth,
      13,
      truncate(orderItem.variation.product.brand.title)
    );
    doc.setFontSize(8);
    doc.setFontType("bold");
    doc.setFont("NotoSerif");
    doc.cell(sizeX, textStartY, sizeWidth, 13, orderItem.variation.sizeStr);
    doc.cell(
      quantityX,
      textStartY,
      quantityWidth,
      13,
      `${orderItem.quantity}`,
      null,
      "right"
    );
    doc.cell(
      unitPriceX,
      textStartY,
      unitPriceWidth,
      13,
      `${orderItem.price} KWD`,
      null,
      "right"
    );
    doc.cell(
      subtotalX,
      textStartY,
      subtotalWidth,
      13,
      `${orderItem.total} KWD`,
      null,
      "right"
    );
    y = y + 27;
  });

  // summary
  textStartY = y + 1;
  doc.setDrawColor(128, 128, 128);
  doc.rect(310, y, 260, 20);
  doc.setDrawColor("#ffffff");
  doc.setFontSize(10);
  doc.cell(313, textStartY, 100, 13, "");
  doc.cell(313, textStartY, 100, 13, "Subtotal:");
  doc.cell(
    310 + 100,
    textStartY,
    158,
    13,
    `${
      order.address
        ? order.total - order.shipping + order.discount - order.giftWrappingPrice
        : order.total + order.discount - order.giftWrappingPrice
    } KWD`,
    null,
    "right"
  );

  y = y + 20;
  textStartY = y + 1;
  doc.setDrawColor(128, 128, 128);
  doc.rect(310, y, 260, 20);
  doc.setDrawColor("#ffffff");
  doc.cell(313, textStartY, 100, 13, "");
  doc.cell(313, textStartY, 100, 13, "Shipping:");
  doc.cell(
    310 + 100,
    textStartY,
    158,
    13,
    `${order.shipping} KWD`,
    null,
    "right"
  );
  
  if (order.giftWrappingPrice !== 0){
    y = y + 20;
    textStartY = y + 1;
    doc.setDrawColor(128, 128, 128);
    doc.rect(310, y, 260, 20);
    doc.setDrawColor("#ffffff");
    doc.cell(313, textStartY, 100, 13, "");
    doc.cell(313, textStartY, 100, 13, "Gift Wrapping:");
    doc.cell(
      310 + 100,
      textStartY,
      158,
      13,
      `${order.giftWrappingPrice} KWD`,
      null,
      "right"
    );
  }
  

  if (order.discount !== 0) {
    y = y + 20;
    textStartY = y + 1;
    doc.setDrawColor(128, 128, 128);
    doc.rect(310, y, 260, 20);
    doc.setDrawColor("#ffffff");
    doc.cell(313, textStartY, 100, 13, "");
    doc.cell(313, textStartY, 100, 13, "Discount:");
    doc.cell(
      310 + 100,
      textStartY,
      158,
      13,
      `${order.discount} KWD`,
      null,
      "right"
    );
  }

  y = y + 20;
  textStartY = y + 1;
  doc.setFontSize(11);
  doc.setDrawColor(128, 128, 128);
  doc.setFillColor(249, 250, 251);
  doc.rect(310, y, 260, 24, "F");
  doc.rect(310, y, 260, 24);
  doc.setDrawColor("#ffffff");
  doc.setFillColor(249, 250, 251);
  doc.cell(313, textStartY, 100, 11, "");
  doc.cell(313, textStartY, 100, 11, "Total:");
  doc.setFillColor(249, 250, 251);
  doc.cell(310 + 100, textStartY, 158, 11, `${order.total} KWD`, null, "right");

  doc.save(`Invoice_${order.id}.pdf`);
  // doc.setProperties({
  //   title: `order_${order.id}`
  // });
  // window.open(doc.output('bloburl'))
};

export default generatePDF;
