import { TableCell, TableRow } from "@mui/material";

import React from "react";

const OrderRow = ({ id, status, total }) => {
  return (
    <TableRow hover>
      <TableCell>{id}</TableCell>
      <TableCell style={{ color: status.color }}>{status.title}</TableCell>
      <TableCell>{total}</TableCell>
    </TableRow>
  );
};

export default OrderRow;
