import { Button, Dialog } from "@mui/material";
import React, { useState } from "react";

import Loading from "../../../common/Loading";
import { PAYMENT_METHODS } from "../../../../Graphql/Mutations/Payments";
import PaymentModal from "./PaymentModal";
import { useMutation } from "@apollo/client";

const PaymentLink = ({ order }) => {
  const [getPaymentMethods, { data, loading, called }] = useMutation(
    PAYMENT_METHODS
  );
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    getPaymentMethods({ variables: { orderId: order.id } });
    setOpen(true);
  };

  if (loading && called)
    return (
      <Dialog open={true}>
        <Loading />
      </Dialog>
    );

  return (
    <>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        className="my-2 mx-2"
      >
        Generate Payment link
      </Button>
      {data ? (
        <PaymentModal
          order={order}
          paymentMethods={data.paymentMethods.paymentMethods}
          open={open}
          handleClose={() => setOpen(false)}
          retrieving={loading && called}
        />
      ) : null}
    </>
  );
};

export default PaymentLink;
