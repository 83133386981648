import { Chip, TableCell, TableRow } from "@mui/material";

import React from "react";

const ExchangeRow = ({ exchangeItem: { orderitemPtr: item } }) => {
  const inventory = item.variation.inventory;

  return (
    <TableRow className="text-nowrap">
      <TableCell padding="checkbox">
        <img
          src={item.variation.product.image}
          height="45px"
          className="ml-2 my-2"
          alt={item.variation.product.title}
        />
      </TableCell>
      <TableCell>
        <p className="mb-0">{item.variation.product.title}</p>
        <p className="mb-0 small text-muted">{item.variation.sizeStr}</p>
      </TableCell>
      <TableCell>
        {item.quantity}X{item.price}
      </TableCell>

      <TableCell align="right">
        {inventory < item.quantity ? (
          <Chip
            label={inventory > 0 ? `Only ${inventory} left` : "Out of Stock"}
            style={{ backgroundColor: "#ffcdd2" }}
          />
        ) : (
          <Chip label="in Stock" style={{ backgroundColor: "#c8e6c9" }} />
        )}
      </TableCell>
    </TableRow>
  );
};

export default ExchangeRow;
