import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";

import React from "react";
import { paymentStatus } from "../../../../constants";
import { useHistory } from "react-router-dom";

const ReturnRequests = ({ returnRequests }) => {
  const history = useHistory();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="bg-light" colSpan={4}>
              <Typography variant="h6">
                Return Requests ({returnRequests.length})
              </Typography>
            </TableCell>
          </TableRow>
          {returnRequests.length ? (
            returnRequests.map(returnRequest => (
              <TableRow
                key={returnRequest.id}
                hover
                onClick={() => history.push(`/returns/${returnRequest.id}`)}
              >
                <TableCell>{returnRequest.id}</TableCell>
                <TableCell>{returnRequest.created}</TableCell>
                <TableCell>
                  {returnRequest.complete ? "completed" : "Awaiting Approval"}
                </TableCell>
                <TableCell>
                  {paymentStatus[returnRequest.paymentStatus]}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>No Return Requests</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReturnRequests;
