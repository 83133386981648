import React, { createContext, useContext, useEffect, useState } from "react";

import Cookies from "js-cookie";
// Apollo
import gql from "graphql-tag";
import jwt_decode from "jwt-decode";
import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";

export const USER_FIELDS = gql`
  fragment UserFields on UserNode {
    id
    isStaff
    isSuperuser
  }
`;

const ME = gql`
  query {
    me {
      id
      isStaff
      isSuperuser
    }
  }
`;

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const client = useApolloClient();

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get("token");
      if (token) {
        const currentTime = Date.now() / 1000;
        const { exp } = jwt_decode(token);
        if (exp >= currentTime) {
          const {
            data: { me }
          } = await client.query({ query: ME });
          if (me) setUser(me);
        } else {
          history.replace("/login");
          Cookies.remove("token");
          setUser(null);
          client.cache.reset();
        }
      }
      setLoading(false);
    }
    loadUserFromCookies();
  }, []);

  const logout = () => {
    history.replace("/login");
    Cookies.remove("token");
    setUser(null);
    client.resetStore();
  };

  const login = async ({ token }) => {
    Cookies.set("token", token, { expires: 30 });
    const {
      data: { me }
    } = await client.query({ query: ME });

    if (me) setUser(me);
    history.push("/orders");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!user, user, loading, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}

// export function StaffProtectRoute(Component) {
//   return () => {
//     const { isAuthenticated, loading, user } = useAuth();
//     const history = useHistory();

//     if (!isAuthenticated && !loading) history.push("/login");

//     if (loading || !isAuthenticated) return <Loading />;

//     if (!user.isStaff) history.replace("/login");

//     return <Component {...arguments} />;
//   };
// }

// export function SuperuserProtectRoute(Component) {
//   return () => {
//     const { isAuthenticated, loading, user } = useAuth();
//     const history = useHistory();

//     if (!isAuthenticated && !loading) history.push("/login");

//     if (loading || !isAuthenticated) return <Loading />;

//     if (!user.isSuperuser) history.replace("/login");

//     return <Component {...arguments} />;
//   };
// }
