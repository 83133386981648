import { Box, Table, TableBody, TableContainer, TextField } from "@mui/material";
import React, { useState } from "react";

import ErrorMessage from "../../common/ErrorMessage";
import Loading from "../../common/Loading";
import ProductTable from "./ProductTable";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const PRODUCTS = gql`
  query($productTypeId: Int) {
    products(productType: $productTypeId) {
      id
      title
      brand {
        title
      }
    }
  }
`;

const ProductsTable = ({ productTypeId }) => {
  const { loading, data, error } = useQuery(PRODUCTS, {
    variables: { productTypeId }
  });
  const [search, setSearch] = useState("");

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  const { products } = data;

  const handleChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const productTables = products.filter(product => product.title.toLowerCase().includes(search.trim()))
  .map(product => (
    <ProductTable key={product.id} product={product} />
  ));

  return (
    <TableContainer component={Box} maxHeight="85vh">
      <TextField value={search} onChange={handleChange} placeholder="Search" size="small" fullWidth variant="outlined"/>
      <Table stickyHeader size="small">
        <TableBody>{productTables}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductsTable;
