import { TableCell, TableRow } from "@mui/material"

import React from "react"

const CashierInfo = ({firstName, lastName, report}) => {
  return (
    <TableRow hover>
      <TableCell style={{fontSize:15}}>{`${firstName} ${lastName}`}</TableCell>
      <TableCell style={{fontSize:15}}>{report.orderCount}</TableCell>
      <TableCell style={{fontSize:15}}>{report.revenue ? report.revenue : 0} KD</TableCell>
    </TableRow>
  )
}

export default CashierInfo