import { Box, Paper, Tab, Tabs } from "@mui/material";
import { useRouteMatch, withRouter } from "react-router-dom";

import EmployeeReport from "./EmployeeReport.js";
// componenets
import ProfitReport from "./ProfitReport";
import React from "react";

const Reports = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs onChange={handleChange} value={value}>
          <Tab label="Business Reports" value={0} />
          <Tab label="Employee Reports" value={1} />
        </Tabs>
      </Box>
      <Paper component={Box} p={2} variant="outlined">
        <Box hidden={value !== 0 }>
          <ProfitReport />
        </Box>
        <Box hidden={value !== 1}>
          <EmployeeReport/>
        </Box>
      </Paper>
      
    </>
    // <Switch>
    //   <Route exact path={`${match.path}/profit`}>
    //     <ProfitReport />
    //   </Route>
    // </Switch>
  );
};
export default withRouter(Reports);
