import React from "react";

const AddressInfo = ({ address }) => {
  return (
    <p>
      <b>name:</b> {address.firstName} {address.lastName}
      <br />
      <b>phone number:</b> {address.phoneNumber}
      {address.user && address.user.email && (
        <>
          <br />
          <b>email:</b> {address.user.email}
        </>
      )}
      <br />
      <b>address:</b> <br />
      {address.address} <br />
      {address.address2 ? (
        <>
          {address.address2},<br />
        </>
      ) : null}
      {address.city && address.city.name},{address.country.name}
      <br />
    </p>
  );
};

export default AddressInfo;
