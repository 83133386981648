import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
} from "@mui/material"
import React, { useState } from "react"

import CloseIcon from '@mui/icons-material/Close';
import { ConfirmationModal } from "../../common";
import { DELETE_PURCHASE_ITEM } from "../../../Graphql/Mutations/Purchases";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

export const UPDATE_PURCHASE_ITEM = gql`
  mutation updatePurchaseItem(
    $purchaseItemId: Int!
    $cost: Float
    $costKwd: Float
    $variationId: Int
  ) {
    updatePurchaseItem(
      purchaseItemId: $purchaseItemId
      cost: $cost
      costKwd: $costKwd
      variationId: $variationId
    ) {
      purchaseItem {
        id
        cost
        costKwd
        inventoryPtr{
          id
          total
        }
        variation{
            id
            product{
                id
                variations{
                    id
                    price
                    sizeStr
                    inventorySet{
                        id
                        purchaseitem{
                            id
                        }
                    }
                }
            }
        }
      }
    }
  }
`;


const PurchaseItemModal = ({purchaseitem, orderItem, variation, open, setOpen, product, setReturned}) => {
  const [deletePurchaseItem] = useMutation(DELETE_PURCHASE_ITEM);
  const [updatePurchaseItem] = useMutation(UPDATE_PURCHASE_ITEM)

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [values, setValues] = useState({
    variationId: variation.id,
    cost: purchaseitem.cost,
    costKwd: purchaseitem.costKwd,
  })

  const handleChange = ({target}) => {
    setValues({...values, [target.name]: target.value})
  }

  const handleUpdate = () => {
    updatePurchaseItem({
      variables: { 
        ...values,
        purchaseItemId: purchaseitem.id,
       }
    });
    setOpen(false);
  }

  const handleDelete = () => {
    deletePurchaseItem({
      variables: { purchaseItemId: purchaseitem.id }
    });
    setOpen(false);
    setReturned(true)
  }

  return (
  <Dialog open={open} onClose={() => setOpen(false)}>
    <DialogTitle>
        <Box display={"flex"} justifyContent="space-between">
            {product.title}
            <IconButton onClick={() => setOpen(false)}><CloseIcon /></IconButton>
        </Box>
    </DialogTitle>
    <DialogContent>
      <Box p={2}>
        <TextField
            select
            name="variationId"
            label="variation"
            value={values.variationId}
            fullWidth
            variant="outlined"
            onChange={handleChange}
            disabled={orderItem}
          >
            {product.variations.map((variation) => (
              <MenuItem key={variation.id} value={variation.id}>
                {variation.sizeStr}
              </MenuItem>
            ))}
          </TextField>
          <Box m={2}/>
          <TextField 
            name="cost"
            label="Cost" 
            value={values.cost}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">{purchaseitem.purchase.currency}</InputAdornment>,
            }}
            />
            <Box m={2}/>
            <TextField 
                name="costKwd"
                label="cost KWD" 
                value={values.costKwd}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                InputProps={{
                endAdornment: <InputAdornment position="end">KWD</InputAdornment>,
                }}
            />
      </Box>
    </DialogContent>
      <Box display={"flex"} justifyContent="space-between" p={1}>
        <Button
          onClick={() => setOpenConfirmation(true)}
          disabled={orderItem}
          color="secondary"
        >
          Delete
        </Button>
        <Button 
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Box>
      <ConfirmationModal
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        action={handleDelete}
      />
  </Dialog>
  )
}

export default PurchaseItemModal