import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery
} from "@mui/material/";

// components
import ProductRow from "./ProductRow";
import React from "react";
import { useTheme } from "@mui/material/styles";

const ProductsTable = ({
  products,
  handleVariationChange,
  handleQuantityChange,
  handleGiftWrapChange,
  removeProduct
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const productsRow = products.map((product, index) => (
    <ProductRow
      product={product}
      index={index}
      handleVariationChange={handleVariationChange}
      handleQuantityChange={handleQuantityChange}
      handleGiftWrapChange={handleGiftWrapChange}
      removeProduct={removeProduct}
      key={index}
    />
  ));
  const headers = isMobile
    ? ["Product", "variant", "quantity", "price", "gift", ""]
    : ["Product", "", "variant", "quantity", "price", "gift", ""];

  return (
    <TableContainer
      component={Box}
      mt={3}
      p={2}
      border={1}
      borderRadius="borderRadius"
      borderColor="grey.300"
    >
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, idx) => (
              <TableCell key={idx}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {products.length === 0 ? <caption>No items added</caption> : null}
        <TableBody>{productsRow}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductsTable;
