import { Box, Button, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";

import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { ErrorAlert } from "../../../common";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const CONNECT_ORDER_ITEM = gql`
mutation($id: Int!){
  connectOrderItemToBookkeeping(orderItemId:$id){
    orderItem{
      id
      inventorySet{
        id
      }
    }
  }
}
`

const ItemRow = ({ item }) => {
  const [connectOrderItem] = useMutation(CONNECT_ORDER_ITEM)
  const [error, setError] = useState(null)
  const SalePrice = item.inventorySet?.length > 0 && item.inventorySet[0].discount > 0 ? item.price - item.inventorySet[0].discount : null

  const handleConnectOrderItem = async () => {
    try {
      await connectOrderItem({variables: {id: item.id}})
    } catch (e) {
      setError(e.message);
    }
    
  }

  return (
    <TableRow className="text-nowrap">
      <TableCell padding="checkbox">
        <img
          src={item.variation.product.image}
          height="45px"
          className="ml-2 my-2"
          alt={item.variation.product.title}
        />
      </TableCell>
      <TableCell style={{ fontSize: "15px", minWidth: "120px" }}>
        <p>{item.variation.product.title}</p>
        <p className="small text-muted">{item.variation.sizeStr}</p>
      </TableCell>
      <TableCell style={{ fontSize: "15px" }} padding="none">
        {item.isGiftWrapped && (
          <CardGiftcardIcon fontSize="small" color="secondary" />
        )}
        {
          item.inventorySet && item.inventorySet.length < item.quantity && 
          <Button onClick={handleConnectOrderItem} size="small" color="secondary">connect</Button>
        }
      </TableCell>
      <TableCell style={{ fontSize: "15px" }} align="right">
      {item.quantity} x <Box component={"span"} color={SalePrice && "red"} sx={{textDecoration: SalePrice && "line-through"}}>{item.price}</Box> {SalePrice}
      </TableCell>
      <TableCell style={{ fontSize: "15px" }} align="right">
        {item.total}
      </TableCell>
      <ErrorAlert error={error} handleClose={() => setError(null)} />
    </TableRow>
  );
};

export default ItemRow;
