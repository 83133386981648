import "bootstrap/dist/css/bootstrap.min.css";
import "typeface-roboto";
import "./index.css";

import * as Sentry from "@sentry/browser";
import * as serviceWorker from "./serviceWorker";

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import App from "./App";
import { AuthProvider } from "./authentication";
import Cookies from "js-cookie";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

const httpLink = createHttpLink({
  uri: "https://axlgoods.com/api/graphql/"
  // uri: "http://127.0.0.1:8001/api/graphql/",
  // connectToDevTools: true
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : ""
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

Sentry.init({
  dsn:
    "https://b4961cc1bd4c4f1bae43a37019e9a451@o405413.ingest.sentry.io/5270974"
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
      </LocalizationProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
