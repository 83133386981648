// components
import { DateSearch, ErrorMessage, Loading } from "../../common";
import React, { useState } from "react";

import OrdersTable from "./OrdersTable";
import ReportSummary from "./ReportSummary";
import gql from "graphql-tag";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";

const REPORT = gql`
  query($startDate: String, $endDate: String) {
    report(startDate: $startDate, endDate: $endDate) {
      orders {
        id
        status {
          id
          title
          color
        }
        total
      }
      orderCount
      revenue
      profit
    }
  }
`;

const ProfitReport = () => {
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const { data, loading, error, refetch } = useQuery(REPORT, {
    variables: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    }
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  const { report } = data;

  return (
    <>
      <DateSearch
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        refetch={refetch}
      />
      <ReportSummary {...report} />
      <OrdersTable {...report} />
    </>
  );
};

export default ProfitReport;
