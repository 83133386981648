import { ORDER_FIELDS } from "../Fragments/Orders";
import gql from "graphql-tag";

export const INITIAL_QUERY = gql`
  query {
    orderSources {
      id
      title
      color
    }
    orderStatuses {
      id
      title
      sequence
      color
    }
    countries {
      id
      name
      cities {
        id
        name
        shipping
      }
    }
  }
`;

export const STATUSES = gql`
  query {
    orderStatuses {
      id
      title
      sequence
      color
    }
  }
`;

export const SOURCES = gql`
  query {
    orderSources {
      id
      title
      color
    }
  }
`;

export const COUNTRIES = gql`
  query {
    countries {
      id
      name
      cities {
        id
        name
        shipping
      }
    }
  }
`;

export const ORDERS = gql`
  query($search: String, $startDate: String, $endDate: String) {
    orders(search: $search, startDate: $startDate, endDate: $endDate) {
      ...OrderFields
      createdBy{
        firstName
        lastName
      }
    }
  }
  ${ORDER_FIELDS}
`;

export const ORDER = gql`
  query($id: Int!) {
    order(id: $id) {
      id
      shipping
      total
      onlinePaymentMethod
      isPickup
      discount
      isGiftWrapped
      status {
        id
        title
        sequence
        color
      }
      source {
        title
        color
      }
      createdDate
      paymentMethod
      paymentStatus
      user {
        username
        email
        firstName
        lastName
      }
      address {
        user {
          id
          email
        }
        id
        firstName
        lastName
        phoneNumber
        address
        address2
        country {
          id
          name
        }
        city {
          id
          name
          shipping
        }
      }
      orderItems {
        id
        quantity
        price
        total
        isGiftWrapped
        inventorySet{
          id
          discount
        }
        variation {
          id
          price
          product {
            id
            title
            image
            brand {
              title
            }
          }
          sizeStr
        }
      }
      giftWrappingPrice
      gift {
        fromName
        toName
        message
      }
      returnRequests {
        id
        created
        complete
        paymentStatus
      }
    }
  }
`;

export const MIN_ORDER = gql`
  query($id: Int!) {
    order(id: $id) {
      id
      total
      discount
      shipping
      giftWrappingPrice
      address {
        id
        address
        country {
          name
        }
      }
      status {
        id
        title
      }
      orderItems {
        id
        quantity
        price
        total
        variation {
          id
          price
          product {
            id
            title
            image
          }
          sizeStr
        }
      }
    }
  }
`;

export const CONTACTS = gql`
  query($username: String!) {
    contacts(username: $username) {
      id
      username
      email
      firstName
      lastName
      addresses {
        id
        firstName
        lastName
        phoneNumber
        address
        address2
        country {
          id
          name
        }
        city {
          id
          name
          shipping
        }
      }
    }
  }
`;
