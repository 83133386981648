import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import React, { useRef, useState } from "react";

import { Autocomplete } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import Loading from "../../common/Loading";
import { SOURCES_CURRENCIES } from "../../../Graphql/Queries/Purchases";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";

const Summary = ({ total, clearProducts, createAction, isConsignment, setIsConsignment }) => {
  const { data,  loading } = useQuery(SOURCES_CURRENCIES, {onCompleted: ({sources}) => {
    const stockX = sources.find(source => parseInt(source.id) === 403);
    setSource(stockX ? stockX : sources[0])
  }});
  const [purchaseDate, setPurchaseDate] = useState(moment(new Date()));
  const [source, setSource] = useState(null);
  const [internalShipping, setInternalShipping] = useState(0);

  const totalCost = internalShipping
    ? total + parseFloat(internalShipping)
    : total;

  let formEl = useRef(null);

  if (loading) return <Loading />;
  const sources = data.sources;
  const currencies = data.currencies;

  const clear = () => {
    clearProducts();
    setInternalShipping(0);
    formEl.reset();
  };

  const createPurchase = e => {
    e.preventDefault();
    const data = new FormData(e.target);
    let input = {};
    const stringDate = moment(purchaseDate).format("YYYY-MM-DD");
    if (isConsignment)
      input = {
        sourceId: source.id,
        requestedPrice: 0,
        arrivalDate: stringDate
      };
    else
      input = {
        sourceId: source.id,
        currency: data.get("currency"),
        purchaseDate: stringDate,
        internalShipping: parseFloat(data.get("shipping"))
      };

    createAction(input, isConsignment);
    clear();
  };

  return (
    <form ref={form => (formEl = form)} onSubmit={createPurchase}>
      <div className="border rounded p-4">
        <FormLabel component="legend" className="my-2">
          Purchase Date
        </FormLabel>
        <DatePicker
          name="purchaseDate"
          variant="inline"
          format="D MMM YYYY"
          inputVariant="outlined"
          size="small"
          allowKeyboardControl
          autoOk
          disableFuture
          value={purchaseDate}
          onChange={setPurchaseDate}
          fullWidth
        />

        <div className="row mt-3">
          <div className="col-md-6 col-12">
            <FormLabel component="legend">Source</FormLabel>
            <Autocomplete
              disablePortal
              options={sources}
              getOptionLabel={option => option.title}
              value={source}
              onChange={(e, value) => {if (value) setSource(value)}}
              renderInput={(params) => <TextField {...params} variant="outlined" size="small"/>}
            />
          </div>
          <div className="col-md-6 col-12">
            <FormLabel component="legend">Internal Shipping</FormLabel>
            <TextField
              disabled={isConsignment}
              name="shipping"
              type="number"
              variant="outlined"
              size="small"
              value={internalShipping}
              onChange={e => setInternalShipping(e.target.value)}
              fullWidth
              required
              InputProps={{ inputProps: { step: 0.01 } }}
            />
          </div>
        </div>

        <FormControl component="fieldset" className="d-block mt-3">
          <FormLabel component="legend">Currency</FormLabel>
          <RadioGroup
            name="currency"
            defaultValue={currencies[0].name}
            className="d-block"
          >
            {currencies.map(currency => (
              <FormControlLabel
                key={currency.name}
                value={currency.name}
                control={<Radio />}
                label={currency.name}
                disabled={isConsignment}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              checked={isConsignment}
              onChange={e => {
                const checked = e.target.checked;
                setIsConsignment(checked);
                if (checked) setInternalShipping(0);
              }}
              name="consignment"
            />
          }
          className="ml-0 text-muted mt-2"
          value="start"
          label="Consignment"
          labelPlacement="start"
        />
        <hr />
        <h1>Total Cost: {totalCost}</h1>
      </div>
      <Button
        type="button"
        className="my-3 float-right"
        color="secondary"
        size="large"
        onClick={clear}
      >
        Clear
      </Button>
      <Button
        type="submit"
        variant="contained"
        className="my-3 float-right"
        color="primary"
        size="large"
        disabled={total === 0}
      >
        Add Purchase
      </Button>
    </form>
  );
};

export default Summary;
