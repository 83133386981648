import React from "react";
import { Typography } from "@mui/material";

const ErrorMessage = ({ error }) => {
  return (
    <center>
      <Typography color="secondary" variant="h1">
        Oops!
      </Typography>
      <Typography variant="h6">Something went wrong</Typography>
      <Typography variant="body1">
        {error.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </Typography>
    </center>
  );
};

export default ErrorMessage;
