import {
  Link,
  TableCell,
  TableRow
} from "@mui/material";
import React, { useState } from "react";

import ConsignmentModal from "./ConsignmentModal";
import PurchaseItemModal from "./PurchaseItemModal";

const backgroundColor = "#C1C1C1";

const InventoryRow = ({ variation, inventory, cellStyle, product }) => {
  const [open, setOpen] = useState(false);
  const [returned, setReturned] = useState(false);
  

  const profit = inventory.orderItem
    ? Number(
          inventory.orderItem.price -
          inventory.total -
          inventory.discount
        ).toFixed(3)
    : null;
  
  const expectedProfit = inventory.total !== null ? ((variation.salePrice ? variation.salePrice : variation.price) - inventory.total).toFixed(3) : "NA"

  const profitColor = profit !== null ? profit > 0 ? "#e7f7ce" : "#ffeeed" : "";

  return (
    <>
      <TableRow hover onClick={()=>setOpen(true)}>
        <TableCell style={cellStyle}>
          {variation.sizeStr.split("|")[0]}
        </TableCell>
        <TableCell style={cellStyle} align="right">
          {inventory.isConsignment
            ? "NA"
            : `${inventory.purchaseitem.cost} ${inventory.purchaseitem.purchase.currency}`}
        </TableCell>
        <TableCell
          style={
            inventory.isConsignment || inventory.purchaseitem.costKwd !== null
              ? cellStyle
              : { ...cellStyle, backgroundColor }
          }
          align="right"
        >
          {inventory.isConsignment
            ? inventory.total
            : inventory.purchaseitem.costKwd}
        </TableCell>
        <TableCell
          style={
            inventory.isConsignment ||
            inventory.purchaseitem.internalShipping !== null
              ? cellStyle
              : { ...cellStyle, backgroundColor }
          }
          align="right"
        >
          {inventory.isConsignment ? (
            returned ? (
              "Returned"
            ) : (
              <Link
                className="pointer"
                onClick={() => setOpen(true)}
                disabled={!inventory.orderItem === null}
              >
                {inventory.consignment.source.title}
              </Link>
            )
          ) : ( inventory.purchaseitem.internalShipping
          )}
        </TableCell>
        <TableCell
          style={
            inventory.isConsignment || inventory.purchaseitem.shipping !== null
              ? cellStyle
              : { ...cellStyle, backgroundColor }
          }
          align="right"
        >
          {inventory.isConsignment ? (
            returned ? (
              "Returned"
            ) : (
              <Link
                className="pointer"
                onClick={() => setOpen(true)}
                disabled={!inventory.orderItem === null}
              >
                C
              </Link>
            )
          ) : (
            inventory.purchaseitem.shipping
          )}
        </TableCell>
        <TableCell
          style={
            inventory.total !== null
              ? cellStyle
              : { ...cellStyle, backgroundColor }
          }
          align="right"
        >
          {inventory.total}
        </TableCell>
        <TableCell style={cellStyle} align="right">
          {inventory.orderItem
            ? Number(
                inventory.orderItem.total / inventory.orderItem.quantity -
                  inventory.discount
              ).toFixed(3)
            : "Not sold"}
        </TableCell>
        <TableCell
          style={{ backgroundColor: profitColor, ...cellStyle }}
          align="right"
        >
          {!inventory.isConsignment && returned ? "Deleted" : inventory.orderItem ? profit : expectedProfit}
        </TableCell>
        <TableCell padding="none" align="right">
          {inventory.orderItem ? inventory.orderItem.order.id : "-"}
        </TableCell>
      </TableRow>

      {inventory.isConsignment ? 
        <ConsignmentModal {...inventory} {...variation} open={open} setOpen={setOpen} setReturned={setReturned}/>
        :
        <PurchaseItemModal open={open} setOpen={setOpen} {...inventory} variation={variation} product={product} setReturned={setReturned}/>
      }
    </>
  );
};

export default InventoryRow;
