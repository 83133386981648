import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead
} from "@mui/material";

import OrderRow from "./OrderRow";
import React from "react";

const OrdersTable = ({ orders }) => {
  const headers = ["Order #", "Status", "Total"];

  const headerCells = headers.map(header => <TableCell>{header}</TableCell>);

  const rows = orders.map(order => <OrderRow key={order.id} {...order} />);
  return (
    <TableContainer component={Box} boxShadow={3} marginTop={3}>
      <Table size="small">
        <TableHead>{headerCells}</TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersTable;
