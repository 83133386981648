import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography
} from "@mui/material";

import { LOGIN } from "../../Graphql/Mutations/Authentication";
import React from "react";
import useAuth from "../../authentication";
import { useMutation } from "@apollo/client";

const Login = () => {
  const { login } = useAuth();
  const [loginUser, { called, loading, error }] = useMutation(LOGIN, {
    fetchPolicy: "no-cache",
    onCompleted: async data => {
      await login(data.tokenAuth);
    }
  });
  const handleSubmit = async e => {
    e.preventDefault();
    const data = new FormData(e.target);
    const input = {
      email: data.get("email"),
      password: data.get("password")
    };
    try {
      await loginUser({ variables: input });
    } catch (e) {}
  };

  return (
    <Box
      p={3}
      mt={7}
      component={Paper}
      variant="outlined"
      className="col-lg-4 mx-auto"
    >
      <Typography variant="h5">Login</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          name="email"
          label="email"
          variant="outlined"
          size="small"
          required
          fullWidth
          className="my-2"
        />
        <TextField
          name="password"
          label="password"
          required
          type="password"
          variant="outlined"
          size="small"
          fullWidth
          className="my-2"
        />
        <p className="text-danger">
          {error &&
            error.graphQLErrors.map(({ message }, i) => (
              <span key={i}>{message}</span>
            ))}
        </p>
        <Button
          type="submit"
          color="secondary"
          fullWidth
          variant="outlined"
          className="my-2"
        >
          {called && loading ? <CircularProgress size={20} /> : "Login"}
        </Button>
      </form>
    </Box>
  );
};

export default Login;
