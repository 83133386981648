import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@mui/material";

import ExchangeRow from "./ExchangeRow";
import React from "react";
// components
import ReturnRow from "./ReturnRow";

const ReturnTable = ({ returnItems, exchangeItems, setReturnedItems }) => {
  const returnItemRows = returnItems.map(returnItem => (
    <ReturnRow
      item={returnItem}
      id={returnItem.id}
      setItems={setReturnedItems}
      key={returnItem.id}
    />
  ));
  const exchangeItemRows = exchangeItems.map(exchangeItem => (
    <ExchangeRow exchangeItem={exchangeItem} key={exchangeItem.id} />
  ));

  const noItemsRow = (
    <TableRow>
      <TableCell colSpan={4} align="center">
        No items
      </TableCell>
    </TableRow>
  );
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell colSpan={3} className="bg-light">
              Returns
            </TableCell>
            <TableCell align="right" className="bg-light">
              Approve
            </TableCell>
          </TableRow>
          {returnItemRows.length ? returnItemRows : noItemsRow}

          <TableRow>
            <TableCell colSpan={3} className="bg-light">
              Exchange
            </TableCell>
            <TableCell align="right" className="bg-light">
              Stock
            </TableCell>
          </TableRow>
          {exchangeItemRows.length ? exchangeItemRows : noItemsRow}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReturnTable;
