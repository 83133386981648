import gql from "graphql-tag";

export const PAYMENT_METHODS = gql`
  mutation paymentMethods($orderId: Int!) {
    paymentMethods(orderId: $orderId) {
      paymentMethods {
        PaymentMethodId
        PaymentMethodEn
        PaymentMethodCode
        ServiceCharge
        TotalAmount
        CurrencyIso
        ImageUrl
      }
    }
  }
`;

export const PAYMENT_URL = gql`
  mutation(
    $orderId: Int!
    $paymentMethodId: Int!
    $currency: String!
  ) {
    paymentUrl(
      orderId: $orderId
      paymentMethodId: $paymentMethodId
      currency: $currency
    ) {
      paymentUrl
    }
  }
`;
