import React, { useState } from "react";

import AddressInfo from "./AddressInfo";
import EditContactModal from "./EditContactModal";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";

const Address = ({ order }) => {
  const [editContact, setEditcontact] = useState(false);
  return (
    <div className="mt-md-0 mt-5">
      <h3>
        Contact
        <IconButton
          size="small"
          className="float-right"
          onClick={() => setEditcontact(true)}
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
      </h3>

      <AddressInfo address={order.address} />

      <EditContactModal
        open={editContact}
        handleClose={() => setEditcontact(false)}
        contact={order.address}
        orderId={order.id}
      />
    </div>
  );
};

export default Address;
