import { Box, Paper, Typography } from "@mui/material";

import React from "react";

const GiftCard = ({ fromName, toName, message }) => {
  console.log(fromName);
  return (
    <Paper component={Box} borderColor="grey.300" border={1} marginBottom={4}>
      <Box width="100%" padding={2} className="bg-light">
        <Typography variant="h6">Gift Card</Typography>
      </Box>
      <Box padding={6}>
        <Box flexDirection="row" display="flex">
          <Typography component={Box} marginRight={5}>
            <b>From:</b> {fromName}
          </Typography>
          <Typography>
            <b>To:</b> {toName}
          </Typography>
        </Box>
        <hr />
        <Typography variant="body1">{message}</Typography>
      </Box>
    </Paper>
  );
};

export default GiftCard;
