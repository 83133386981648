import { Grid, IconButton } from "@mui/material";

// components
import Address from "./Address/";
import OrderInfo from "./OrderInfo";
import React from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";
// graphql
import { UPDATE_ORDER } from "../../../Graphql/Mutations/Orders";
import { generateDesignedReceipt } from "./Receipt";
import { useMutation } from "@apollo/client";

const Header = ({ order }) => {
  const [updateOrder] = useMutation(UPDATE_ORDER);
  const applyOrderChanges = input => {
    updateOrder({
      variables: {
        id: order.id,
        ...input
      }
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item>
        <h1>
          Order Details
          <IconButton
            color="primary"
            onClick={() => generateDesignedReceipt(order)}
          >
            <ReceiptIcon />
          </IconButton>
        </h1>
        <OrderInfo order={order} updateOrder={applyOrderChanges} />
      </Grid>
      <Grid item>
        {order.address ? (
          <Address order={order} />
        ) : (
          <div>
            <h3>Contact</h3>
            <p>
              <b>name:</b> {order.user.firstName} {order.user.lastNAme}
              <br />
              <b>phone number/email:</b> {order.user.username}
            </p>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default Header;
