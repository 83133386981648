import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

import ItemRow from "./ItemRow";
import React from "react";
import { withStyles } from "@mui/styles";

const GreyTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "rgb(249,250,251)"
  }
}))(TableCell);

const ItemsTable = ({ order }) => {
  const headers = ["Item", "", "", "Unit Price", "Subtotal"].map(
    (header, index) => (
      <GreyTableCell
        key={index}
        align={index > 2 ? "right" : "left"}
        style={{ fontSize: "15px" }}
      >
        {header}
      </GreyTableCell>
    )
  );

  const itemRow = order.orderItems.map((item, index) => (
    <ItemRow key={index} item={item} />
  ));

  return (
    <Paper className="my-5">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>{headers}</TableRow>
          </TableHead>
          <TableBody>{itemRow}</TableBody>
        </Table>
      </TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <GreyTableCell colSpan={5} align="right">
              <h5>
                <b>Items Total: </b>
                {order.total - order.shipping + order.discount - order.giftWrappingPrice} KWD
              </h5>
            </GreyTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={5} align="right">
              <Typography fontWeight="bold">
                <b>Discount: {order.discount} KWD</b>
              </Typography>
            </TableCell>
          </TableRow>
          {order.giftWrappingPrice !== 0 &&
          <TableRow>
            <TableCell colSpan={5} align="right">
              <Typography>
                <b>Gift Wrapping: {order.giftWrappingPrice} KWD</b>
              </Typography>
            </TableCell>
          </TableRow>}
          <TableRow>
            <TableCell colSpan={5} align="right">
              <Typography>
                <b>Shipping: {order.shipping} KWD</b>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={5} align="right">
              <h4>Order Total: {order.total} KWD</h4>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ItemsTable;
