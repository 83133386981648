import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";

import ErrorMessage from "../common/ErrorMessage";
import Loading from "../common/Loading";
// Queries
import { PRODUCT_TYPES } from "../../Graphql/Queries/Products";
import ProductsTable from "./ProductsTable";
import { useQuery } from "@apollo/client";

const Products = () => {
  const { loading, data, error } = useQuery(PRODUCT_TYPES);
  const [productType, setProductType] = useState(0);

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  const { productTypes } = data;

  return (
    <>
      <Tabs
        value={productType}
        onChange={(e, newValue) => setProductType(newValue)}
        indicatorColor="secondary"
        textColor="primary"
      >
        {productTypes.map(({ title }, idx) => (
          <Tab label={title} key={idx} />
        ))}
      </Tabs>
      <ProductsTable productTypeId={productTypes[productType].id} />
    </>
  );
};
export default Products;
