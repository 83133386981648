import {
  Box,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";

import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import React from "react";
import { UPDATE_ORDER } from "../../../Graphql/Mutations/Orders";
import { paymentStatus } from "../../../constants";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

const OrderDetails = ({ order, statuses, nextPayment }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [updateOrder] = useMutation(UPDATE_ORDER);
  const history = useHistory();

  const headers = ["Item", "", "", "Unit Price"].map((header, index) => (
    <TableCell key={index} align={index > 1 ? "right" : "left"}>
      {header}
    </TableCell>
  ));

  const rows = order.orderItems.map((item, index) => (
    <TableRow key={index} className="text-nowrap">
      <TableCell padding="checkbox">
        <img
          height="40px"
          src={item.variation.product.image}
          alt={item.variation.product.title}
        />
      </TableCell>
      <TableCell>
        <p className="pb-0">{item.variation.product.title}</p>
        <p className="small text-muted">{item.variation.sizeStr}</p>
      </TableCell>
      <TableCell padding="none">
        {item.isGiftWrapped && (
          <CardGiftcardIcon fontSize="small" color="secondary" />
        )}
      </TableCell>
      <TableCell align="right">
        {item.quantity} x {item.price}
      </TableCell>
    </TableRow>
  ));

  const updateStatus = e => {
    updateOrder({
      variables: {
        id: order.id,
        status: e.target.value
      }
    });
  };

  const updatePaymentStatus = () => {
    updateOrder({
      variables: {
        id: order.id,
        paymentStatus: paymentStatus[nextPayment]
      }
    });
  };

  return (
    <Box
      p={4}
      style={{
        boxShadow: "inset 1px 1px 2px 0px rgba(0,0,0,.5)"
      }}
    >
      <TextField
        select
        defaultValue={order.status.id}
        onChange={updateStatus}
        disabled={order.status.title.toLowerCase() === "canceled"}
        label="Set Status As"
        variant="outlined"
        size="small"
        className="float-right mb-3"
      >
        {statuses.map(status => (
          <MenuItem
            key={status.id}
            value={status.id}
            disabled={status.sequence === null}
          >
            {status.title}
          </MenuItem>
        ))}
      </TextField>
      <Table>
        <TableHead>
          <TableRow>{headers}</TableRow>
        </TableHead>
        <TableBody>
          {rows}
          {isMobile ? 
            <>
              <TableRow>
                <TableCell colSpan={4} align="left"><b>Discount: {order.discount} KD</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="left"><b>Total: {order.total} KD</b></TableCell>
              </TableRow>
            </>
          : 
            <TableRow>
              <TableCell colSpan={2} align="left"><b>Discount: {order.discount} KD</b></TableCell>
              <TableCell colSpan={2} align="right"><b>Total: {order.total} KD</b></TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
      <Box mt={5} mb={2}>
        <p><b>Created By:</b> {`${order.createdBy?.firstName} ${order.createdBy?.lastName}`}</p>
        {order.isPickup ? (
          <p className="d-inline-block">
            {order.user.firstName} {order.user.lastName}
            <br />
            {order.user.username}
          </p>
        ) : (
          <p className="d-inline-block">
            {order.address &&
              `${order.address.firstName} ${order.address.lastName}`}
            <br />
            {order.address && order.address.address}
            {order.address && order.address.address2},{" "}
            {order.address.city && order.address.city.name},{" "}
            {order.address && order.address.country.name}
          </p>
        )}
        <Button
          color="secondary"
          className="mx-1 float-right"
          onClick={() => history.push(`/orders/${order.id}`)}
        >
          Details
        </Button>
        {nextPayment && order.source.title !== "Website" ? (
          <Button
            variant="contained"
            color="primary"
            className="mx-1 float-right"
            onClick={updatePaymentStatus}
          >
            Set as {paymentStatus[nextPayment]}
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default OrderDetails;
