import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

import React from "react";

const ReportSummary = ({ orderCount, revenue, profit }) => {
  return (
    <Box marginTop={3}>
      <Grid container spacing={3}>
        <Grid item sm xs={12}>
          <Card>
            <CardContent>
              <Typography color="secondary" variant="h6">
                {orderCount}
              </Typography>
              <Box fontWeight="fontWeightBold">Orders</Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm xs={12}>
          <Card>
            <CardContent>
              <Typography color="secondary" variant="h6">
                {revenue} KD
              </Typography>
              <Box fontWeight="fontWeightBold">Revenue</Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm xs={12}>
          <Card>
            <CardContent>
              <Typography color="secondary" variant="h6">
                {profit} KD
              </Typography>
              <Box fontWeight="fontWeightBold">Profit</Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportSummary;
