import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  useMediaQuery
} from "@mui/material";
import { PaymentMethod, paymentStatus } from "../../../constants";

import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OrderDetails from "./OrderDetails";
import React from "react";
import { useTheme } from "@mui/material/styles";

const OrderRow = ({ order, statuses, open, setOpen, index }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const nextPayment = Object.keys(paymentStatus)[
    Object.keys(paymentStatus).findIndex(key => key === order.paymentStatus) + 1
  ];

  return (
    <>
      <TableRow
        hover
        onClick={() => setOpen(order.id, !open)}
        className="pointer"
        style={order.status.title === "Canceled" ? { backgroundColor: "#ffeeed" } : index % 2 === 1 ? { backgroundColor: "#fcfcfc" } : {}}
      >
        {!mobile && (
          <TableCell>
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell scope="row">
          {order.isGiftWrapped && (
            <CardGiftcardIcon fontSize="small" color="secondary" />
          )}
          {order.id}
        </TableCell>
        {!mobile && (
          <TableCell>
            {order.isPickup
              ? "Pickup"
              : order.address && order.address.country.name}
          </TableCell>
        )}
        <TableCell style={{ color: order.status.color }}>
          {order.status.title}
        </TableCell>
        {!mobile && (
          <TableCell style={{ color: order.source.color }}>
            {order.source.title}
          </TableCell>
        )}
        <TableCell>
          {PaymentMethod[order.paymentMethod] === PaymentMethod.ONLINE
            ? paymentStatus[order.paymentStatus] === paymentStatus.PAID
              ? order.onlinePaymentMethod
              : PaymentMethod.ONLINE
            : PaymentMethod[order.paymentMethod]}{" "}
          - {paymentStatus[order.paymentStatus]}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding="none" colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <OrderDetails
              order={order}
              statuses={statuses}
              nextPayment={nextPayment}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OrderRow;
