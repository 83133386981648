import React from "react";
import { Route, Redirect } from "react-router-dom";

import Login from "../components/Authentication/Login";

const Routes = () => {
  return (
    <>
      <Route path="/login" render={props => <Login {...props} />} />
      <Redirect to="/login" />
    </>
  );
};

export default Routes;
