import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField
} from "@mui/material";
import React, { useState } from "react";

import { COUNTRIES } from "../../../../Graphql/Queries/Orders";
import { useApolloClient } from "@apollo/client";

const AddressForm = ({ setShipping, deliveryType }) => {
  const client = useApolloClient();
  const { countries } = client.readQuery({ query: COUNTRIES });

  const [isShippingContact, setIsShippingContact] = useState(true);
  const [country, setCountry] = useState(countries[0]);
  const [city, setCity] = useState(countries[0].cities[0]);

  const isPickup = deliveryType === "Pickup";

  if (!isPickup) setShipping(city.shipping);

  const countryOptions = countries.map(country => (
    <MenuItem key={country.id} value={country.id}>
      {country.name}
    </MenuItem>
  ));

  const cityOptions = country.cities.map(city => (
    <MenuItem key={city.id} value={city.id}>
      {city.name}
    </MenuItem>
  ));

  const handleCityChange = e => {
    const city = country.cities.find(city => city.id === e.target.value);
    setCity(city);
    setShipping(city.shipping);
  };

  const handleCountryChange = e => {
    const country = countries.find(country => country.id === e.target.value);
    setCountry(country);
    setCity(country.cities[0]);
    setShipping(country.cities[0].shipping);
  };

  const handleIsShippingContact = () => {
    setIsShippingContact(prev => !prev);
  };

  return (
    <>
      {isPickup ? (
        <h6 className="text-muted ml-2">Pickup</h6>
      ) : (
        <>
          <FormControlLabel
            control={
              <Checkbox
                name="shipping"
                checked={isShippingContact}
                onChange={handleIsShippingContact}
              />
            }
            label="Shipping same as Contact"
          />
          <br />

          {isShippingContact ? null : (
            <>
              <TextField
                required
                size="small"
                label="First Name"
                name="AddressFirstName"
                variant="outlined"
                className="col-5 mx-2 my-1"
              />
              <TextField
                size="small"
                label="Last Name"
                name="AddressLastName"
                variant="outlined"
                className="col-5 mx-2 my-1"
              />
              <TextField
                required
                size="small"
                label="Phone Number"
                name="phoneNumber"
                variant="outlined"
                className="col-5 mx-2 my-1"
              />
              <br />
            </>
          )}
          <TextField
            required
            size="small"
            label="address"
            name="address"
            variant="outlined"
            className="col-5 mx-2 my-1"
          />
          <TextField
            size="small"
            label="address2"
            name="address2"
            variant="outlined"
            className="col-5 mx-2 my-1"
          />
          <TextField
            select
            label="country"
            value={country.id}
            size="small"
            variant="outlined"
            name="country"
            className="col-5 mx-2 my-1"
            onChange={handleCountryChange}
          >
            {countryOptions}
          </TextField>
          <TextField
            select
            label="city"
            value={city && city.id}
            size="small"
            variant="outlined"
            name="city"
            className="col-5 mx-2 my-1"
            onChange={handleCityChange}
          >
            {cityOptions}
          </TextField>
        </>
      )}
    </>
  );
};

export default AddressForm;
