import gql from "graphql-tag";
import { ORDER_FIELDS } from "../Fragments/Orders";

export const CREATE_ORDER = gql`
  mutation createOrder(
    $source: Int!
    $paymentMethod: String!
    $isPickup: Boolean
    $freeShipping: Boolean
    $variations: [OrderItemInput!]
    $contact: ContactInput!
    $address: AddressInput!
    $discount: Float
    $gift: GiftInput
    $isGiftWrapped: Boolean
  ) {
    createOrder(
      source: $source
      paymentMethod: $paymentMethod
      isPickup: $isPickup
      freeShipping: $freeShipping
      variations: $variations
      address: $address
      contact: $contact
      discount: $discount
      gift: $gift
      isGiftWrapped: $isGiftWrapped
    ) {
      order {
        ...OrderFields
      }
    }
  }
  ${ORDER_FIELDS}
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder(
    $id: Int!
    $status: Int
    $paymentStatus: String
    $paymentMethod: String
  ) {
    updateOrder(
      id: $id
      status: $status
      paymentStatus: $paymentStatus
      paymentMethod: $paymentMethod
    ) {
      order {
        ...OrderFields
      }
    }
  }
  ${ORDER_FIELDS}
`;

export const UPDATE_CONTACT = gql`
  mutation updateContact($orderId: Int!, $address: AddressInput!) {
    updateContact(orderId: $orderId, address: $address) {
      order {
        ...OrderFields
      }
    }
  }
  ${ORDER_FIELDS}
`;

export const CREATE_RETURN_REQUEST = gql`
  mutation createReturnExchange(
    $orderId: Int!
    $orderItems: [OrderItemInput!]
    $returnItems: [ReturnItemInput!]
    $address: AddressInput
  ) {
    createReturnRequest(
      orderId: $orderId
      orderItems: $orderItems
      returnItems: $returnItems
      address: $address
    ) {
      returnRequest {
        id
      }
    }
  }
`;

export const APPROVE_RETURN_REQUEST = gql`
  mutation approveReturnRequest(
    $returnRequestId: Int!
    $isPickup: Boolean!
    $shippingOption: String!
    $discountOption: String
    $returnItems: [ReturnExchangeItemInput!]
  ) {
    approveReturnRequest(
      returnRequestId: $returnRequestId
      isPickup: $isPickup
      shippingOption: $shippingOption
      discountOption: $discountOption
      returnItems: $returnItems
    ) {
      returnRequest {
        id
        dueAmount
        complete
        shippingOption
      }
    }
  }
`;

export const DELETE_RETURN_REQUEST = gql`
  mutation deleteReturnRequest($returnRequestId: Int!) {
    deleteReturnRequest(returnRequestId: $returnRequestId) {
      order {
        id
        status {
          id
          title
        }
        returnRequests {
          id
        }
      }
    }
  }
`;
