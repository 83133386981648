import gql from "graphql-tag";

export const CREATE_PURCHASE = gql`
  mutation createPurchase(
    $sourceId: Int!
    $currency: String!
    $cost: Float!
    $internalShipping: Float!
    $purchaseItems: [PurchaseItemInput!]
    $purchaseDate: Date!
  ) {
    createPurchase(
      sourceId: $sourceId
      currency: $currency
      cost: $cost
      internalShipping: $internalShipping
      purchaseItems: $purchaseItems
      purchaseDate: $purchaseDate
    ) {
      purchase {
        id
        purchaseDate
        source {
          title
        }
        currency
        cost
        costKwd
        purchaseItems {
          id
          cost
          costKwd
          shipping
          total
          arrivalDate
          variation {
            sizeStr
            product {
              title
            }
          }
          isConsignment
          status {
            id
            title
            color
            sequence
          }
        }
      }
    }
  }
`;

export const UPDATE_PURCHASE = gql`
  mutation updatePurchase($purchaseId: Int!, $costKwd: Float, $shippingCost: Float, $notes: String) {
    updatePurchase(purchaseId: $purchaseId, costKwd: $costKwd, shippingCost: $shippingCost, notes: $notes) {
      purchase {
        id
        costKwd
        notes
        purchaseItems {
          id
          costKwd
          shipping
        }
      }
    }
  }
`;

export const DELETE_PURCHASE = gql`
  mutation($purchaseId: Int!, $foreignExchangeExpenses: Float!) {
    deletePurchase(
      purchaseId: $purchaseId
      foreignExchangeExpenses: $foreignExchangeExpenses
    ) {
      msg
    }
  }
`;

export const UPDATE_PURCHASE_ITEM = gql`
  mutation updatePurchaseItem(
    $purchaseItemId: Int!
    $statusId: Int
    $shipping: Float
    $cost: Float
    $costKwd: Float
  ) {
    updatePurchaseItem(
      purchaseItemId: $purchaseItemId
      statusId: $statusId
      shipping: $shipping
      cost: $cost
      costKwd: $costKwd
    ) {
      purchaseItem {
        id
        shipping
        cost
        costKwd
        status {
          id
          title
          color
          sequence
        }
      }
    }
  }
`;

export const DELETE_PURCHASE_ITEM = gql`
  mutation deletePurchaseItem(
    $purchaseItemId: Int!
  ) {
    deletePurchaseItem(
      purchaseItemId: $purchaseItemId
    ) {
      purchase {
        id
        cost
        costKwd
        purchaseItems {
          id
        }
      }
    }
  }
`;

export const FLAG_PURCHASE_ITEM = gql`
  mutation($purchaseItemId: Int!) {
    flagPurchaseItem(purchaseItemId: $purchaseItemId) {
      purchaseItem {
        id
        isFlagged
      }
    }
  }
`;

export const CREATE_CONSIGNMENT = gql`
  mutation createConsignment(
    $arrivalDate: Date!
    $consignmentItems: [PurchaseItemInput!]
    $sourceId: Int!
    $requestedPrice: Float!
  ) {
    createConsignment(
      arrivalDate: $arrivalDate
      consignmentItems: $consignmentItems
      sourceId: $sourceId
      requestedPrice: $requestedPrice
    ) {
      created
    }
  }
`;

export const DELETE_CONSIGNMENT = gql`
  mutation deleteConsignment($consignmentId: Int!) {
    deleteConsignment(consignmentId: $consignmentId) {
      variation {
        id
        inventory
      }
    }
  }
`;
