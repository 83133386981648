import { TextField, Typography } from "@mui/material";

import AddressForm from "./AddressForm";
import React from "react";

const NewContact = ({ setShipping, deliveryType }) => {
  return (
    <div>
      <Typography variant="h6">Contact</Typography>
      <TextField
        required
        size="small"
        label="First Name"
        name="firstName"
        variant="outlined"
        className="col-5 mx-2 my-1"
      />
      <TextField
        size="small"
        label="Last Name"
        name="lastName"
        variant="outlined"
        className="col-5 mx-2 my-1"
      />
      <TextField
        required
        size="small"
        label="Phone Number"
        name="username"
        variant="outlined"
        className="col-5 mx-2 my-1"
      />
      <TextField
        type="email"
        size="small"
        label="Email"
        name="email"
        variant="outlined"
        className="col-5 mx-2 my-1"
      />

      <Typography variant="h6" className="mt-2">
        Address
      </Typography>
      <AddressForm setShipping={setShipping} deliveryType={deliveryType} />
    </div>
  );
};

export default NewContact;
