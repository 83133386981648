import { TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import React from "react";

const VariationRow = ({ variation, idx }) => (
  <>
    <TableRow hover style={idx % 2 === 1 ? { backgroundColor: "#fcfcfc" } : {}}>
      <TableCell className="text-nowrap">
        <Tooltip title={variation.sizeStr} placement="top">
          <b>{variation.sizeStr.split("|")[0]}</b>
        </Tooltip>
      </TableCell>
      <TableCell
        align="center"
        className={
          variation.inStock ? "text-success font-weight-bold" : "text-muted"
        }
      >
        {variation.inventory}
      </TableCell>
      <TableCell
        align="center"
        className={
          variation.incomingInventory === 0
            ? "text-muted"
            : "text-dark-warning font-weight-bold"
        }
      >
        {variation.incomingInventory}
      </TableCell>
      <TableCell align="right">
        <Typography fontWeight={"bold"}><Typography variant={variation.salePrice &&"caption"} component={"span"} sx={{textDecoration:variation.salePrice &&"line-through"}} color={variation.salePrice && "red"}>{variation.price}</Typography>{variation.salePrice?.toFixed(3)}</Typography>
      </TableCell>
    </TableRow>
  </>
);

export default VariationRow;
