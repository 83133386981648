import ApolloCacheUpdater from "apollo-cache-updater";
import jwt_decode from "jwt-decode";
import { ORDERS, INITIAL_QUERY } from "../Queries/Orders";
import { PURCHASES } from "../Queries/Purchases";

export const updateOrdersCache = (proxy, { data: { createOrder = {} } }) => {
  const mutationResult = createOrder.order;
  ApolloCacheUpdater({
    proxy,
    searchOperator: "ANY",
    queriesToUpdate: [ORDERS],
    searchVariables: {},
    mutationResult
  });
};

export const updatePurchasesCache = (
  proxy,
  { data: { createPurchase = {} } }
) => {
  const mutationResult = createPurchase.purchase;
  ApolloCacheUpdater({
    proxy,
    searchOperator: "ANY",
    queriesToUpdate: [PURCHASES],
    searchVariables: {},
    mutationResult
  });
};

export const setUser = async (token, client) => {
  if (token) {
    localStorage.setItem("token", token);
    await client.query({ query: INITIAL_QUERY });
  } else {
    localStorage.removeItem("token");
    client.clearStore();
  }
};

export const checkForToken = client => {
  const token = localStorage.getItem("token");
  if (token) {
    const currentTime = Date.now() / 1000;
    const user = jwt_decode(token);
    if (user.exp >= currentTime) {
      setUser(token, client);
    } else {
      setUser(null, client);
    }
  }
};

export const isAuthenticated = () => {
  return localStorage.getItem("token");
};

export const logout = client => {
  setUser(null, client);
};
