import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import React, { useState } from "react";

import AddressInfo from "../../Orders/Details/Address/AddressInfo";
import Customer from "../../Orders/Create/OrderSummary/Customer";

const Summary = ({ total, createAction, order }) => {
  const [isNewAddress, setIsNewAddress] = useState(null);

  const submitForm = e => {
    e.preventDefault();
    const data = new FormData(e.target);
    const isShippingContact = data.get("shipping");

    let address = {};

    if (!isNewAddress && order.address) address = { id: order.address.id };
    else if (!isNewAddress) address = null;
    else if (data.get("addressId")) address = { id: data.get("addressId") };
    else if (isShippingContact === null)
      address = {
        phoneNumber: data.get("phoneNumber"),
        firstName: data.get("AddressFirstName"),
        lastName: data.get("AddressLastName"),
        address: data.get("address"),
        address2: data.get("address2"),
        countryId: data.get("country"),
        cityId: data.get("city")
      };
    else
      address = {
        phoneNumber: data.get("username"),
        firstName: data.get("firstName"),
        lastName: data.get("lastName"),
        address: data.get("address"),
        address2: data.get("address2"),
        countryId: data.get("country"),
        cityId: data.get("city")
      };
    createAction(address);
  };

  return (
    <form onSubmit={submitForm}>
      <div className="border rounded p-4">
        <FormControl component="fieldset">
          <FormLabel component="legend">Address</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={isNewAddress}
            onChange={e => setIsNewAddress(e.target.value)}
          >
            <FormControlLabel
              value={null}
              control={<Radio />}
              label="Default"
            />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="New Address"
            />
          </RadioGroup>
        </FormControl>
        {isNewAddress ? (
          <Customer setShipping={() => {}} deliveryType={""} />
        ) : (
          <p>
            {order.address ? (
              <AddressInfo address={order.address} />
            ) : (
              "pick up"
            )}
          </p>
        )}
        <h4>Original Total: {order.total - order.shipping} KWD</h4>
        <h4>Discount: {order.discount} KWD</h4>
        <h4>New Total: {total} KWD</h4>

        <hr />
        <h1>Price Difference: {total - (order.total - order.shipping - order.giftWrappingPrice)} KWD</h1>
        <small>Discount included in price difference</small>
      </div>

      <Button
        type="submit"
        variant="contained"
        className="my-3 float-right"
        color="primary"
        size="large"
      >
        Update Order
      </Button>
    </form>
  );
};

export default Summary;
