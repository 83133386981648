import gql from "graphql-tag";

export const SOURCES_CURRENCIES = gql`
  query {
    sources {
      id
      title
    }
    currencies {
      name
    }
  }
`;

export const SOURCES_STATUSES = gql`
  query {
    sources {
      id
      title
    }
    statuses {
      id
      sequence
      title
    }
  }
`;

export const SOURCES = gql`
  query {
    sources {
      id
      title
    }
  }
`;

export const CURRENCIES = gql`
  query {
    currencies {
      name
    }
  }
`;

export const STATUSES = gql`
  query {
    statuses {
      id
      title
      sequence
    }
  }
`;

export const PURCHASES = gql`
  query {
    purchases {
      id
      notes
      purchaseDate
      source {
        title
      }
      currency
      cost
      internalShipping
      costKwd
      purchaseItems {
        id
        isFlagged
        cost
        costKwd
        shipping
        total
        arrivalDate
        variation {
          sizeStr
          product {
            title
          }
        }
        isConsignment
        status {
          id
          title
          color
          sequence
        }
      }
    }
  }
`;
