import { Box, Table, TableBody, TableContainer } from "@mui/material/";

// components
import ProductRow from "./ProductRow";
import React from "react";

const ProductsTable = ({ products, handleChange, removeProduct, isConsignment }) => {
  const productsRow = products.map((product, index) => (
    <ProductRow
      product={product}
      index={index}
      handleChange={handleChange}
      removeProduct={removeProduct}
      isConsignment={isConsignment}
      key={index}
    />
  ));
  return (
    <TableContainer
      component={Box}
      mt={3}
      p={2}
      border={1}
      borderRadius="borderRadius"
      borderColor="grey.300"
    >
      <Table>
        {products.length === 0 ? <caption>No products added</caption> : null}
        <TableBody>{productsRow}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductsTable;
