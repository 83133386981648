import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, { Fragment } from "react";

import { ErrorMessage } from "../../common";
import InventoryRow from "./InventoryRow";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const PRODUCT = gql`
  query($productId: Int!) {
    product(productId: $productId) {
      id
      title
      variations {
        id
        price
        salePrice
        sizeStr
        inventorySet {
          id
          isConsignment
          total
          discount
          orderItem {
            id
            price
            total
            quantity
            order {
              id
            }
          }
          consignment {
            id
            requestedPrice
            source {
              id
              title
            }
          }
          purchaseitem {
            id
            cost
            costKwd
            shipping
            internalShipping
            purchase {
              currency
            }
          }
        }
      }
    }
  }
`;

const VariantionsTable = ({ productId }) => {
  const { data, loading, error } = useQuery(PRODUCT, {
    variables: { productId }
  });

  if (loading)
    return (
      <Box fontWeight="bold" fontSize={16} textAlign="center" margin={5}>
        Loading...
      </Box>
    );
  if (error) return <ErrorMessage error={error} />;

  const variations = data.product.variations.map(
    (variation, variationIndex) => (
      <Fragment key={variationIndex}>
        {variation.inventorySet.map((inventory, inventoryIndex) => {
          const cellStyle =
            variationIndex !== 0 && inventoryIndex === 0
              ? { borderTop: "solid", borderWidth: "1px" }
              : {};

          return (
            <InventoryRow
              key={inventoryIndex}
              variation={variation}
              inventory={inventory}
              product={data.product}
              cellStyle={cellStyle}
            />
          );
        })}
      </Fragment>
    )
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="right">Cost</TableCell>
            <TableCell align="right">Cost KWD</TableCell>
            <TableCell align="right">Int. Shipping</TableCell>
            <TableCell align="right">Shipping</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Profit</TableCell>
            <TableCell align="right">Order</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{variations}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default VariantionsTable;
