import { Box, Button, Paper } from "@mui/material";

import ErrorMessage from "../../common/ErrorMessage";
import { Link } from "react-router-dom";
import React from "react";

const Error = ({ error }) => {
  return (
    <Box component={Paper} variant="outlined" py={2} px={6}>
      <Link to="/orders" className="ml-n5">
        <Button color="secondary">Back to orders</Button>
      </Link>
      <h1>Order Details</h1>
      <ErrorMessage error={error} />
    </Box>
  );
};

export default Error;
