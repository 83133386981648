import React from "react";
import { Switch, Route, useRouteMatch, withRouter } from "react-router-dom";

// componenets
import Create from "./Create";
import List from "./List";
import Details from "./Details";

const Returns = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <List />
      </Route>
      <Route exact path={`${match.path}/:orderId/create`}>
        <Create />
      </Route>
      <Route exact path={`${match.path}/:requestId`}>
        <Details />
      </Route>
    </Switch>
  );
};
export default withRouter(Returns);
