import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import CloseIcon from "@mui/icons-material/Close";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import React from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import TuneIcon from '@mui/icons-material/Tune';
import useAuth from "../../authentication";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { withRouter } from "react-router-dom";

const drawerWidth = 240;


const items = [
  { url: "orders", title: "Orders", Icon: ShoppingCartIcon, superuser: false },
  {
    url: "inventory",
    title: "Inventory",
    Icon: LocalOfferIcon,
    superuser: false
  },
  { url: "purchases", title: "Purchases", Icon: ReceiptIcon, superuser: true },
  {
    url: "accounting",
    title: "Bookkeeping",
    Icon: AccountBalanceIcon,
    superuser: true
  },
  {
    url: "returns",
    title: "Return Requests",
    Icon: AssignmentReturnIcon,
    superuser: false
  },
  {
    url: "products",
    title: "Products",
    Icon: StoreIcon,
    superuser: true
  },
  {
    url: "reports/profit",
    title: "Reports",
    Icon: AssessmentIcon,
    superuser: true
  },
  { url: "orders/custom", title: "Custom", Icon: TuneIcon, superuser: false}
];

const SideNav = ({ mobileOpen, handleDrawerToggle }) => {
  const location = useLocation();
  let history = useHistory();
  const { user } = useAuth();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = path => {
    history.push(path);
    if (matches) handleDrawerToggle()
  };

  const listItems = items
    .filter(({ superuser }) => (superuser && user.isSuperuser) || !superuser)
    .map(({ url, title, Icon }) => (
      <ListItem button onClick={() => handleClick(`/${url}`)} key={url}>
        <ListItemIcon>
          <Icon
            color={
              location.pathname.startsWith(`/${url}`) ? "secondary" : "inherit"
            }
          />
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography
            color={
              location.pathname.startsWith(`/${url}`) ? "secondary" : "inherit"
            }
          >
            {title}
          </Typography>
        </ListItemText>
      </ListItem>
    ));

  return (
    <Drawer
      variant={matches ? "temporary" : "permanent"}
      open={matches ? mobileOpen : true}
      onClose={handleDrawerToggle}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      {matches ? (
        <IconButton
          onClick={handleDrawerToggle}
          sx={{marginRight: "auto", marginLeft: 0}}
        >
          <CloseIcon />
        </IconButton>
      ) : (
        <Toolbar />
      )}

      <List>{listItems}</List>
    </Drawer>
  );
};

export default withRouter(SideNav);
