import { Route, Switch, useRouteMatch, withRouter } from "react-router-dom";

// componenets
import Create from "./Create";
import Custom from "./Custom";
import Details from "./Details";
import List from "./List";
import React from "react";

const Orders = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <List />
      </Route>
      <Route exact path={`${match.path}/custom`}>
        <Custom />
      </Route>
      <Route exact path={`${match.path}/create`}>
        <Create />
      </Route>
      <Route path={`${match.path}/:orderId`}>
        <Details />
      </Route>
    </Switch>
  );
};
export default withRouter(Orders);
