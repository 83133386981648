import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@mui/material";

import Loading from "../../common/Loading";
import React from "react";
// apollo
import gql from "graphql-tag";
import { paymentStatus } from "../../../constants";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

const RETURNS_REQUESTS_LIST = gql`
  query {
    returnRequests {
      id
      created
      dueAmount
      complete
      paymentStatus
    }
  }
`;

const List = () => {
  const { data, loading } = useQuery(RETURNS_REQUESTS_LIST);
  const history = useHistory();

  if (loading) return <Loading />;

  const returnRequests = data.returnRequests;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {returnRequests.map(returnRequest => (
            <TableRow
              key={returnRequest.id}
              hover
              onClick={() => history.push(`/returns/${returnRequest.id}`)}
            >
              <TableCell>{returnRequest.id}</TableCell>
              <TableCell>{returnRequest.created}</TableCell>
              <TableCell>
                {returnRequest.complete ? (
                  <span className="text-success">completed</span>
                ) : (
                  "awaiting approval"
                )}
              </TableCell>
              <TableCell className="text-danger">
                {paymentStatus[returnRequest.paymentStatus]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
