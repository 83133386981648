import {
  APPROVE_RETURN_REQUEST,
  DELETE_RETURN_REQUEST
} from "../../../../Graphql/Mutations/Orders";
import { Box, Button, Grid } from "@mui/material";
// components
import { ConfirmationModal, ErrorAlert } from "../../../common";
import React, { useState } from "react";

import AddressInfo from "../../../Orders/Details/Address/AddressInfo";
import Options from "./Options";
import ReturnTable from "./ReturnTable";
import { useHistory } from "react-router-dom";
// apollo
import { useMutation } from "@apollo/client";

const Return = ({ returnRequest }) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [returnedItems, setReturnedItems] = useState([]);
  const [approveReturnRequest] = useMutation(APPROVE_RETURN_REQUEST);
  const [deleteReturnRequest] = useMutation(DELETE_RETURN_REQUEST);

  const completeReturnRequest = async e => {
    e.preventDefault();
    const data = new FormData(e.target);
    const isPickup =
      returnRequest.address === null
        ? true
        : data.get("isPickup")
        ? false
        : true;
    const shippingOption = data.get("shippingOption");
    const discountOption = data.get("discount");
    const returnItems = returnRequest.returnItems.map(returnItem => {
      return {
        id: returnItem.id,
        approved: returnedItems.includes(returnItem.id)
      };
    });

    const variables = {
      returnRequestId: returnRequest.id,
      isPickup,
      shippingOption,
      discountOption,
      returnItems
    };

    if (returnRequest.order.discount === 0) delete variables["discountOption"];

    try {
      await approveReturnRequest({ variables });
    } catch (error) {
      setError(error.message);
    }
  };

  const cancelReturnRequest = async () => {
    try {
      await deleteReturnRequest({
        variables: { returnRequestId: returnRequest.id }
      });
      history.replace(`/orders/${returnRequest.order.id}`);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <form onSubmit={completeReturnRequest}>
        <Box className="float-md-right float-none">
          {returnRequest.address ? (
            <AddressInfo address={returnRequest.address} />
          ) : (
            <div>
              <h3>Contact</h3>
              <p>
                <b>name:</b> {returnRequest.order.user.firstName}{" "}
                {returnRequest.order.user.lastNAme}
                <br />
                <b>phone number/email:</b> {returnRequest.order.user.username}
              </p>
            </div>
          )}
        </Box>
        <Box className="row" mx={4} mb={4}>
          <Options
            address={returnRequest.address}
            discount={returnRequest.order.discount}
          />
        </Box>

        <ReturnTable
          returnItems={returnRequest.returnItems}
          setReturnedItems={setReturnedItems}
          exchangeItems={returnRequest.exchangeItems}
        />
        <Grid
          component={Box}
          marginY={3}
          container
          spacing={2}
          justify="space-between"
        >
          <Grid item>
            <Button color="secondary" onClick={() => setOpen(true)}>
              Cancel Request
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" color="secondary" variant="contained">
              Submit Request
            </Button>
          </Grid>
        </Grid>
      </form>

      <ErrorAlert error={error} handleClose={() => setError(null)} />
      <ConfirmationModal
        open={open}
        handleClose={() => setOpen(false)}
        action={cancelReturnRequest}
      />
    </>
  );
};

export default Return;
