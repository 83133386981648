import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React, {useState} from "react"
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";

import Color from 'color';

const ToggleButtonSx = (theme) => (
  {
    "&.Mui-selected": {
      backgroundColor: Color(theme.palette.secondary.main).alpha(0.1).string(),
      color: theme.palette.secondary.main
    }
  }
)

const DISCOUNT_TYPES = {
  AMOUNT: "$",
  PERCENTAGE:"%"
}

const Discount = ({total, setDiscountAmount, discountAmount, setFreeShipping, freeShipping}) => {
  const [discountType, setDiscountType] = useState(DISCOUNT_TYPES.AMOUNT);
  const [discount, setDiscount] = useState(0);

  const handleChange = (event, value) => {
    if (value === null) value = DISCOUNT_TYPES.AMOUNT
    setDiscountType(value);
    calculateDiscount(discount, value)
  };

  const handleDiscountChange = ({target}) => {
    let value = target.value
    if (value < 0) value = 0
    setDiscount(value)
    calculateDiscount(value, discountType)
    
  }

  const calculateDiscount = (amount, type) => {
    if (type === DISCOUNT_TYPES.AMOUNT)
      setDiscountAmount(amount)
    else if (type === DISCOUNT_TYPES.PERCENTAGE)
      setDiscountAmount(total * amount/100)
  }

  return (
    <>
      <FormControlLabel
          control={
            <Checkbox
              name="shipping"
              value={freeShipping}
              onChange={() => setFreeShipping(prev => !prev)}
            />
          }
          label="Free Shipping"
        />
        <br />
        <TextField
          label="discount"
          variant="outlined"
          size="small"
          value={discount}
          type="number"
          onChange={handleDiscountChange}
          helperText={`discount: ${discountAmount}KD`}
        />
        <ToggleButtonGroup
          value={discountType}
          exclusive
          size="small"
          onChange={handleChange}
          aria-label="text alignment"
          sx={{height:"40px"}}
        >
          <ToggleButton  value={DISCOUNT_TYPES.AMOUNT} aria-label="left aligned" sx={ToggleButtonSx}>
            <Box mx={1} fontWeight="bold" fontSize={18}>{DISCOUNT_TYPES.AMOUNT}</Box>
          </ToggleButton>
          <ToggleButton  value={DISCOUNT_TYPES.PERCENTAGE} aria-label="centered" sx={ToggleButtonSx}>
          <Box mx={1} fontWeight="bold" fontSize={18}>{DISCOUNT_TYPES.PERCENTAGE}</Box>
          </ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}

export default Discount