import React from "react";
import { Switch, Route, useRouteMatch, withRouter } from "react-router-dom";

// componenets
// import Create from "./Create";
import List from "./List";
import Details from "./Details";

const Inventory = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <List />
      </Route>
      {/* <Route exact path={`${match.path}/create`}>
        <Create />
      </Route> */}
      <Route path={`${match.path}/detailed/`}>
        <Details />
      </Route>
    </Switch>
  );
};
export default withRouter(Inventory);
