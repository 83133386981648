import { Box } from "@mui/material";
import CompletedReturnRequest from "./CompletedReturnRequest";
// components
import Loading from "../../common/Loading";
import Order from "./Order";
import React from "react";
import ReturnRequest from "./ReturnRequest";
// apollo
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

const RETURN_REQUEST = gql`
  query($id: Int!) {
    returnRequest(id: $id) {
      id
      created
      createdBy{
        id
        firstName
        lastName
      }
      dueAmount
      shippingOption
      discountOption
      isPickup
      complete
      paymentStatus
      address {
        phoneNumber
        firstName
        lastName
        address
        address2
        country {
          name
        }
        city {
          name
          shipping
        }
      }
      order {
        id
        discount
        user {
          firstName
          lastName
          username
        }
        orderItems {
          id
          price
          quantity
          total
          variation {
            id
            sizeStr
            product {
              id
              title
              image
            }
          }
        }
      }
      returnItems {
        id
        variation {
          id
          sizeStr
          product {
            id
            title
            image
          }
        }
        quantity
        price
        total
        approved
      }
      exchangeItems {
        id
        approved
        orderitemPtr {
          id
          variation {
            id
            sizeStr
            inventory
            product {
              id
              title
              image
            }
          }
          price
          quantity
          total
        }
      }
    }
  }
`;

const Details = () => {
  let { requestId } = useParams();

  const { data, loading } = useQuery(RETURN_REQUEST, {
    variables: { id: requestId }
  });

  if (loading) return <Loading />;

  const returnRequest = data.returnRequest;

  return (
    <Box>
      <h4 className="mb-0">Return Request #{returnRequest.id}</h4>
      <p className="small text-muted mb-0">{returnRequest.created}</p>
      <hr />

      {returnRequest.complete ? (
        <CompletedReturnRequest returnRequest={returnRequest} />
      ) : (
        <ReturnRequest returnRequest={returnRequest} />
      )}
      <Order order={returnRequest.order} />
    </Box>
  );
};

export default Details;
