import { DISCOUNT_OPTIONS, SHIPPING_FEES_OPTIONS } from "../../../../constants";
import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import React, { useState } from "react";

const ReturnRequestOptions = ({ address, discount }) => {
  const [values, setValues] = useState({
    isPickup: null,
    shippingOption: null,
    discount: null
  });

  const handleChange = e => {
    const selected = isNaN(e.target.value)
      ? e.target.value
      : parseInt(e.target.value);
    setValues({ ...values, [e.target.name]: selected });
  };

  return (
    <FormGroup>
      <RadioGroup
        name="isPickup"
        className="d-block my-2"
        value={values.isPickup}
        onChange={handleChange}
      >
        <FormLabel component="legend">Delivery Type</FormLabel>
        <FormControlLabel
          value={1}
          control={<Radio required />}
          label="Pick Up"
        />
        <FormControlLabel
          value={0}
          control={<Radio required />}
          label="Delivery"
        />
      </RadioGroup>

      <RadioGroup
        name="shippingOption"
        className="d-block my-2"
        value={values.shippingOption}
        onChange={handleChange}
      >
        <FormLabel component="legend">
          Shipping Fees ({`${address?.city?.shipping ?? 0} KD`})
        </FormLabel>
        {Object.keys(SHIPPING_FEES_OPTIONS).map((option, index) => (
          <FormControlLabel
            key={index}
            value={option}
            control={<Radio required />}
            label={SHIPPING_FEES_OPTIONS[option]}
          />
        ))}
      </RadioGroup>

      <RadioGroup
        name="discount"
        className="d-block my-2"
        value={values.discount}
        onChange={handleChange}
      >
        <FormLabel component="legend">
          Discount Options ({discount} KD){" "}
        </FormLabel>
        {Object.keys(DISCOUNT_OPTIONS).map((option, index) => (
          <FormControlLabel
            disabled={discount === 0}
            key={index}
            value={option}
            control={<Radio required />}
            label={DISCOUNT_OPTIONS[option]}
          />
        ))}
      </RadioGroup>
    </FormGroup>
  );
};

export default ReturnRequestOptions;
