import gql from "graphql-tag";

export const AVAILABLE_PRODUCTS = gql`
  query($active: Boolean, $available: Boolean, $search: String, $new: Boolean) {
    products(active: $active, available: $available, search: $search, new: $new) {
      title
      image
      id
      productType {
        title
      }
      variations {
        id
        inStock
        active
        price
        salePrice
        inventory
        sizeStr
      }
    }
  }
`;

export const PRODUCTS = gql`
  query {
    products {
      title
      id
      productType {
        title
      }
      variations {
        id
        price
        inventory
        incomingInventory
        sizeStr
      }
    }
  }
`;

export const PRODUCT_TYPES = gql`
  query {
    productTypes {
      id
      title
    }
  }
`;
