import { Box, Paper } from "@mui/material";
// Components
import { CreateButton, DateSearch, ErrorMessage, Loading } from "../../common";
import React, { useState } from "react";

import Filters from "./Filters";
// graphql
import { ORDERS } from "../../../Graphql/Queries/Orders";
import OrdersTable from "./OrdersTable";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";

const List = () => {
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [values, setValues] = useState({
    status: "",
    source: "",
    country: "",
    payment: "",
    query: ""
  });

  const { data, loading, refetch, error } = useQuery(ORDERS, {
    variables: {
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD")
    }
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  let orders = data.orders;

  orders = orders.map((order) =>
    ({...order, createdDate: moment(order.createdDate).tz("Asia/Kuwait").format("D MMM YYYY") })
  );


  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value.toLowerCase() });
  };

  const handleSubmit = search => {
    refetch({ search });
  };

  const filteredOrders = orders.filter(order => {
    if (
      values.status !== "" &&
      values.status !== "all" &&
      order.status.title.toLowerCase() !== values.status
    )
      return false;
    if (
      values.source !== "" &&
      values.source !== "all" &&
      order.source.title.toLowerCase() !== values.source
    )
      return false;
    if (
      values.country !== "" &&
      values.country !== "all" &&
      order.address &&
      order.address.country.name.toLowerCase() !== values.country
    )
      return false;
    if (
      values.payment !== "" &&
      values.payment !== "all" &&
      order.paymentStatus.toLowerCase() !== values.payment
    )
      return false;
    if (
      !order.id.includes(values.query) &&
      order.address &&
      !order.address.phoneNumber.includes(values.query)
    )
      return false;
    return true;
  });

  return (
    <Box className="m-lg-5">
      <Box className="m-lg-2">
        <CreateButton url="/orders/create" text="Create Order" />
        <DateSearch
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          refetch={refetch}
        />
      </Box>
      <Box variant="outlined" component={Paper} p={2} pt={3} mt={3}>
        <Filters handleChange={handleChange} handleSubmit={handleSubmit}/>
        <OrdersTable orders={filteredOrders} />
      </Box>
    </Box>
  );
};

export default List;
