import {
  FormControlLabel,
  MenuItem,
  Switch,
  TextField
} from "@mui/material";

import React from "react";
import { SOURCES_STATUSES } from "../../../Graphql/Queries/Purchases";
import { useQuery } from "@apollo/client";

const Filters = ({ handleChange }) => {
  const { data } = useQuery(SOURCES_STATUSES, {
    fetchPolicy: "cache-only"
  });

  const sources = [{ id: "0", title: "All" }, ...data.sources];
  const statuses = [{ id: "0", title: "All" }, ...data.statuses];

  return (
    <>
      <TextField
        select
        label="Source"
        name="source"
        defaultValue="0"
        onChange={handleChange}
        className="mx-2 col-2"
      >
        {sources.map(source => (
          <MenuItem key={source.id} value={source.id}>
            {source.title}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Statuses"
        name="status"
        defaultValue="0"
        onChange={handleChange}
        className="mx-2 col-2"
      >
        {statuses.map(status => (
          <MenuItem key={status.id} value={status.id}>
            {status.title}
          </MenuItem>
        ))}
      </TextField>
      <FormControlLabel
        control={
          <Switch
            onChange={handleChange}
            name="costKwd"
            color="secondary"
            className="mx-2"
          />
        }
        label="Missing KWD Cost"
      />
      <FormControlLabel
        control={
          <Switch
            onChange={handleChange}
            name="shipping"
            color="secondary"
            className="mx-2"
          />
        }
        label="Missing Shipping"
      />
    </>
  );
};

export default Filters;
