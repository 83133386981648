import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";

// Queries
import { CONTACTS } from "../../../../Graphql/Queries/Orders";
// components
import ContactsList from "./ContactsList";
import NewContact from "./NewContact";
import { useQuery } from "@apollo/client";

const Customer = ({ deliveryType, setShipping }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [newContact, setNewContact] = useState(false);
  const { called, loading, data, refetch } = useQuery(CONTACTS, {
    fetchPolicy: "network-only",
    skip: !phoneNumber
  });

  const retrieveContact = async () => {
    try {
      await refetch({ username: phoneNumber });
      setNewContact(false);
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <TextField
        size="small"
        label="Phone Number"
        variant="outlined"
        className="col-md-5 col-11 mr-2 my-1"
        value={phoneNumber}
        onChange={e => setPhoneNumber(e.target.value)}
      />
      <Button
        variant="contained"
        className="my-1"
        color="primary"
        disabled={(called && loading) || !phoneNumber}
        onClick={retrieveContact}
      >
        {called && loading ? (
          <>
            <CircularProgress size="20px" /> Retrieving Contacts
          </>
        ) : (
          "Retrieve Contacts"
        )}
      </Button>
      <br />
      {data ? (
        <>
          {newContact ? (
            <>
              <Button onClick={() => setNewContact(false)} color="secondary">
                Back to contacts
              </Button>
              <NewContact
                setShipping={setShipping}
                deliveryType={deliveryType}
              />
            </>
          ) : (
            <>
              <Button onClick={() => setNewContact(true)} color="secondary">
                New Contact
              </Button>
              <br />
              <ContactsList
                contacts={data.contacts}
                setShipping={setShipping}
                deliveryType={deliveryType}
              />
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default Customer;
