import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import ErrorMessage from "../common/ErrorMessage";
import Loading from "../common/Loading";
import gql from "graphql-tag";

export const PRODUCTS = gql`
  query($productTypeId: Int) {
    priorityProducts(productType: $productTypeId) {
      id
      title
      brand {
        title
      }
    }
  }
`;

export const PRODUCT_SORT = gql`
  mutation sortProducts($products: [Int]) {
    productSort(products: $products) {
      success
    }
  }
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ProductsTable = ({ productTypeId }) => {
  const { loading, data, error } = useQuery(PRODUCTS, {
    variables: { productTypeId }
  });
  const [orderedProducts, setOrderedProducts] = useState(null);
  const [reorderProducts, { loading: reorderLoading }] = useMutation(
    PRODUCT_SORT,
    {
      update: (cache, { data }) => {
        if (data.productSort.success) {
          cache.writeQuery({
            query: PRODUCTS,
            data: { products: orderedProducts },
            variables: { productTypeId }
          });
        }
      }
    }
  );

  useEffect(() => {
    setOrderedProducts(data?.products ?? null);
  }, [data]);

  if (loading || reorderLoading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  const { priorityProducts: products } = data;

  if (orderedProducts === null) setOrderedProducts(products);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      orderedProducts,
      result.source.index,
      result.destination.index
    );

    setOrderedProducts(items);
  };

  return (
    <>
      <Paper>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <TableContainer
                component={Box}
                maxHeight="80vh"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Table stickyHeader size="small">
                  <TableBody>
                    {orderedProducts.map((product, index) => (
                      <Draggable
                        key={product.id}
                        draggableId={product.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TableCell
                              padding="checkbox"
                              className={
                                snapshot.isDragging ? "bg-dark text-light" : ""
                              }
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell className="bg-light font-weight-bold">
                              <p className="mb-0">{product.title}</p>
                              <p className="small text-muted mb-0">
                                {product.brand.title}
                              </p>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                  </TableBody>
                  {provided.placeholder}
                </Table>
              </TableContainer>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>

      <Button
        onClick={() =>
          reorderProducts({
            variables: {
              products: orderedProducts.map(product => product.id)
            }
          })
        }
        variant="contained"
        color="secondary"
        component={Box}
        mt={2}
      >
        Reorder
      </Button>
    </>
  );
};

export default ProductsTable;
