import { Box, Container, Paper, Typography } from "@mui/material"

import ProductCustomItem from "./ProductCustomItem"
import React from "react"

const Custom = () => {

  return (
    <Container>
      <Typography variant="h5" gutterBottom mb={2}>Custom Order</Typography>
      
      <Paper component={Box} p={3} mt={3}>
        <ProductCustomItem/>
      </Paper>
    </Container>
  )
}

export default Custom