import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  TextField
} from "@mui/material";
import React, { useState } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { PAYMENT_URL } from "../../../../Graphql/Mutations/Payments";
import { useMutation } from "@apollo/client";

const PaymentModal = ({
  order: { id },
  paymentMethods,
  open,
  handleClose
}) => {
  const [getPaymentUrl, { data, loading, called }] = useMutation(PAYMENT_URL);
  const [paymentMethodIndex, setPaymentMethodIndex] = useState(0);

  const handleSubmit = async e => {
    e.preventDefault();
    const paymentMethod = paymentMethods[paymentMethodIndex];
    const input = {
      orderId: id,
      paymentMethodId: paymentMethod.PaymentMethodId,
      currency: paymentMethod.CurrencyIso ? paymentMethod.CurrencyIso : "KWD"
    };

    try {
      await getPaymentUrl({ variables: input });
    } catch (e) {}
  };

  const url = data && data.paymentUrl && data.paymentUrl.paymentUrl;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Generate Payment Link</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            name="payment"
            select
            variant="outlined"
            label="Payment Method"
            value={paymentMethodIndex}
            onChange={e => setPaymentMethodIndex(e.target.value)}
            fullWidth
          >
            {paymentMethods.map((paymentMethod, index) => (
              <MenuItem key={paymentMethod.PaymentMethodId} value={index}>
                {paymentMethod.PaymentMethodEn}
              </MenuItem>
            ))}
          </TextField>

          <div className={called ? "mt-4" : "d-none"}>
            {loading ? (
              <LinearProgress color="secondary" />
            ) : (
              <>
                <TextField
                  variant="outlined"
                  size="small"
                  value={url}
                  className="w-75 mr-2"
                />
                <CopyToClipboard text={url}>
                  <Button variant="contained" color="primary">
                    Copy
                  </Button>
                </CopyToClipboard>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="secondary" size="large">
            generate payment link
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PaymentModal;
