import { Paper, Table, TableBody, TableContainer } from "@mui/material";

import PurchaseRow from "./PurchaseRow";
import React from "react";

const PurchaseTable = ({ purchases }) => {
  const rows = purchases.map(purchase => (
    <PurchaseRow key={purchase.id} purchase={purchase} />
  ));

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>{rows}</TableBody>
        <caption>{purchases.length} Purchases</caption>
      </Table>
    </TableContainer>
  );
};

export default PurchaseTable;
