export const paymentStatus = {
  NOT_PAID: "Not Paid",
  PAID: "Paid"
};

export const SHIPPING_FEES_OPTIONS = {
  FREE: "Free Shipping",
  INCLUDE: "Include Shipping",
  EVEN: "Break Even"
};

export const currency = {
  USD: "USD",
  KWD: "KWD",
  EUR: "EUR",
  GBP: "GPB"
};

export const PaymentMethod = {
  ONLINE: "Online",
  CASH: "Cash",
  CARD: "Card"
};

export const variationStatusColors = {
  Available: "green",
  Incoming: "orange",
  "Not Available": "red"
};

export const DISCOUNT_OPTIONS = {
  RETURNED: "Discount Returned",
  REMAINS: "Discount Remains",
  PARTIAL: "Partial Discount"
};
