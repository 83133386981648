import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TableCell,
  TableRow
} from "@mui/material";
import React, { useState } from "react";

const ReturnRow = ({ item, id, setItems }) => {
  const [isApproved, setIsApproved] = useState(null);

  const handleChange = e => {
    const approved = parseInt(e.target.value);
    if (approved) {
      setItems(items => {
        let list = [...items];
        list.push(id);
        return list;
      });
    } else {
      setItems(items => {
        let list = [...items];
        list.splice(
          items.findIndex(itemId => itemId === id),
          1
        );
        return list;
      });
    }

    setIsApproved(approved);
  };

  return (
    <TableRow className="text-nowrap">
      <TableCell padding="checkbox">
        <img
          src={item.variation.product.image}
          height="45px"
          className="ml-2 my-2"
          alt={item.variation.product.title}
        />
      </TableCell>
      <TableCell>
        <p className="mb-0">{item.variation.product.title}</p>
        <p className="mb-0 small text-muted">{item.variation.sizeStr}</p>
      </TableCell>
      <TableCell>
        {item.quantity}X{item.price}
      </TableCell>

      <TableCell padding="checkbox">
        <RadioGroup
          name="approve"
          className="d-block"
          value={isApproved}
          onChange={handleChange}
        >
          <FormControlLabel
            value={1}
            control={<Radio required />}
            label="Approve"
          />
          <FormControlLabel
            value={0}
            control={<Radio required />}
            label="Reject"
          />
        </RadioGroup>
      </TableCell>
    </TableRow>
  );
};

export default ReturnRow;
