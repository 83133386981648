import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import SideNav from "./SideNav";
import logo from "../../static/images/soledw.png";
import useAuth from "../../authentication";

const Nav = ({ isSuperuser }) => {
  const { logout, isAuthenticated } = useAuth();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => mobileOpen ? 1 : theme.zIndex.drawer + 100, marginBottom: "20px" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{mr:2, display:{lg:"none"}}}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} height="30px" alt="soled logo" />
          <Typography variant="h6" sx={{mb:2, mt:"auto", flexGrow:1}}>
            POS
          </Typography>
          {isAuthenticated ? (
            <Button color="inherit" className="float-right" onClick={logout}>
              Logout
            </Button>
          ) : null}
        </Toolbar>
      </AppBar>
      <SideNav
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        isSuperuser={isSuperuser}
      />
    </>
  );
};

export default Nav;
