import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

import React from "react";

const ConfirmationModal = ({ open, handleClose, action }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"Are you sure?"}</DialogTitle>
      <DialogActions>
        <Button onClick={action} color="primary">
          Yes
        </Button>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
