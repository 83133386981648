import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";

import { COUNTRIES } from "../../../../Graphql/Queries/Orders";
import React from "react";
import { UPDATE_CONTACT } from "../../../../Graphql/Mutations/Orders";

const EditContactModal = ({ contact, orderId, open, handleClose }) => {
  const {
    data: { countries }
  } = useQuery(COUNTRIES, {
    fetchPolicy: "cache-only"
  });
  const [updateContact] = useMutation(UPDATE_CONTACT);

  const cityOptions = countries
    .find(country => country.id === contact.country.id)
    .cities.map(city => (
      <MenuItem key={city.id} value={city.id}>
        {city.name}
      </MenuItem>
    ));

  const handleSubmit = e => {
    e.preventDefault();
    const data = new FormData(e.target);

    const address = {
      phoneNumber: data.get("phoneNumber"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      address: data.get("address"),
      address2: data.get("address2"),
      countryId: contact.country.id,
      cityId: data.get("city"),
      userId: contact.user.id
    };

    updateContact({ variables: { address: address, orderId: orderId } });
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Edit Conntact</DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            size="small"
            label="First Name"
            name="firstName"
            variant="outlined"
            className="col-5 ml-2 my-1"
            defaultValue={contact.firstName}
          />
          <TextField
            size="small"
            label="Last Name"
            name="lastName"
            variant="outlined"
            className="col-5 ml-2 my-1"
            defaultValue={contact.lastName}
          />
          <TextField
            required
            size="small"
            label="Phone Number"
            name="phoneNumber"
            variant="outlined"
            className="col-5 ml-2 my-1"
            defaultValue={contact.phoneNumber}
          />
          <br />
          <TextField
            required
            size="small"
            label="address"
            name="address"
            variant="outlined"
            className="col-5 ml-2 my-1"
            defaultValue={contact.address}
          />
          <TextField
            size="small"
            label="address2"
            name="address2"
            variant="outlined"
            className="col-5 ml-2 my-1"
            defaultValue={contact.address2}
          />
          <TextField
            required
            disabled
            size="small"
            label="country"
            name="country"
            variant="outlined"
            className="col-5 ml-2 my-1"
            defaultValue={contact.country.name}
          />
          <TextField
            select
            label="city"
            defaultValue={contact.city && contact.city.id}
            size="small"
            variant="outlined"
            name="city"
            className="col-5 ml-2 my-1"
          >
            {cityOptions}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            type="submit"
          >
            Edit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditContactModal;
