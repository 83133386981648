import AddressInfo from "../../../Orders/Details/Address/AddressInfo";
import { Box } from "@mui/material";
import React from "react";
import ReturnOptions from "./ReturnOptions";
import ReturnTable from "./ReturnTable";

const completeReturnRequest = ({ returnRequest }) => {
  return (
    <>
      <Box className="float-md-right float-none">
        {returnRequest.address ? (
          <AddressInfo address={returnRequest.address} />
        ) : (
          <div>
            <p>
              <b>name:</b> {returnRequest.order.user.firstName}{" "}
              {returnRequest.order.user.lastNAme}
              <br />
              <b>phone number/email:</b> {returnRequest.order.user.username}
            </p>
          </div>
        )}
      </Box>
      <Box mx={4} mb={4}>
        <ReturnOptions returnRequest={returnRequest} />
      </Box>

      <ReturnTable
        returnItems={returnRequest.returnItems}
        exchangeItems={returnRequest.exchangeItems}
      />
    </>
  );
};

export default completeReturnRequest;
