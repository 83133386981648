import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
// Queries
import { COUNTRIES, SOURCES } from "../../../../Graphql/Queries/Orders";
import React, { useRef, useState } from "react";

// components
import Customer from "./Customer";
import Discount from "./Discount";
import { PaymentMethod } from "../../../../constants";
import { useApolloClient } from "@apollo/client";

const OrderSummary = ({ total, createAction, clearProducts }) => {
  const client = useApolloClient();
  const { countries } = client.readQuery({ query: COUNTRIES });
  const { orderSources: sources } = client.readQuery({ query: SOURCES });

  const payments = ["Payment Link", "Cash"];
  const deliveryTypes = ["Delivery", "Pickup"];

  const [deliveryType, setDeliveryType] = useState(deliveryTypes[0]);
  const [shipping, setShipping] = useState(countries[0].cities[0].shipping);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [freeShipping, setFreeShipping] = useState(false);
  let formEl = useRef(null);

  const clear = () => {
    clearProducts();
    formEl.reset();
  };

  const createOrder = e => {
    e.preventDefault();
    const data = new FormData(e.target);
    const source = data.get("source");
    const paymentMethod = data.get("payment") === "Cash" ? PaymentMethod.CASH : PaymentMethod.ONLINE;
    const isShippingContact = data.get("shipping");
    const contactId = data.get("contact");
    const isPickup = deliveryTypes[1] === deliveryType;

    let address = {};
    let contact;

    if (contactId === null)
      contact = {
        username: data.get("username"),
        email: data.get("email"),
        firstName: data.get("firstName"),
        lastName: data.get("lastName")
      };
    else contact = { id: contactId };

    if (!isPickup) {
      if (data.get("addressId")) address = { id: data.get("addressId") };
      else if (isShippingContact === null)
        address = {
          phoneNumber: data.get("phoneNumber"),
          firstName: data.get("AddressFirstName"),
          lastName: data.get("AddressLastName"),
          address: data.get("address"),
          address2: data.get("address2"),
          countryId: data.get("country"),
          cityId: data.get("city")
        };
      else
        address = {
          phoneNumber: data.get("username"),
          firstName: data.get("firstName"),
          lastName: data.get("lastName"),
          address: data.get("address"),
          address2: data.get("address2"),
          countryId: data.get("country"),
          cityId: data.get("city")
        };
    }
    const input = {
      source,
      address,
      paymentMethod,
      contact,
      isPickup: isPickup ? true : false,
      discount: discountAmount,
      freeShipping
    };

    createAction(input);
  };

  const paymentOptions = payments.map(payment => (
    <FormControlLabel
      key={payment}
      value={payment}
      control={<Radio />}
      label={payment}
    />
  ));
  const sourceOptions = sources.map(source => (
    <FormControlLabel
      key={source.id}
      value={source.id}
      control={<Radio />}
      label={source.title}
      style={{ color: source.color }}
    />
  ));

  const paymentDefault = payments[0];
  const sourceDefault = sources[0].id;

  return (
    <form onSubmit={createOrder} ref={form => (formEl = form)}>
      <div className="border rounded p-4">
        <FormControl component="fieldset" className="d-block">
          <FormLabel component="legend">Payment Method</FormLabel>
          <RadioGroup
            name="payment"
            defaultValue={paymentDefault}
            className="d-block"
          >
            {paymentOptions}
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" className="d-block">
          <FormLabel component="legend">Payment Source</FormLabel>
          <RadioGroup
            name="source"
            defaultValue={sourceDefault}
            className="d-block"
          >
            {sourceOptions}
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" className="d-block">
          <FormLabel component="legend">Contact</FormLabel>
          <RadioGroup
            name="deliveryType"
            value={deliveryType}
            onChange={e => {
              setDeliveryType(e.target.value);
              if (e.target.value === deliveryTypes[1]) setShipping(0);
            }}
            className="d-block"
          >
            {deliveryTypes.map(deliveryType => (
              <FormControlLabel
                key={deliveryType}
                value={deliveryType}
                control={<Radio />}
                label={deliveryType}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Customer deliveryType={deliveryType} setShipping={setShipping} />
        <hr />
        <h4 className="mb-0">Subtotal: {total} KWD</h4>
        <h4 className="mt-0">Shipping: {freeShipping ? 0 : shipping} KWD</h4>
        <Discount total={total} setFreeShipping={setFreeShipping} freeShipping={freeShipping} setDiscountAmount={setDiscountAmount} discountAmount={discountAmount}/>
        <hr />
        <h1>
          Total: {freeShipping ? total - discountAmount : total + shipping - discountAmount}{" "}
          KWD
        </h1>
      </div>
      <Button
        type="button"
        className="my-3 float-right"
        color="secondary"
        size="large"
        onClick={clear}
      >
        Clear
      </Button>
      <Button
        type="submit"
        variant="contained"
        className="my-3 float-right"
        color="primary"
        size="large"
        disabled={total === 0}
      >
        Create Order
      </Button>
    </form>
  );
};

export default OrderSummary;
