import { Box, Table, TableBody, TableContainer } from "@mui/material/";

// components
import ProductRow from "./ProductRow";
import React from "react";

const ProductsTable = ({ products, setProducts }) => {
  const removeProduct = index => {
    let newProducts = [...products];
    newProducts.splice(index, 1);

    setProducts(newProducts);
  };

  const handleVariationChange = (productIndex, variationIndex) => {
    let newProducts = [...products];
    const product = {
      ...newProducts[productIndex],
      selectedVariation: variationIndex,
      quantity: 1
    };
    newProducts[productIndex] = product;
    setProducts([...newProducts]);
  };

  const handleQuantityChange = (productIndex, quantity) => {
    let newProducts = [...products];
    newProducts[productIndex].quantity = quantity;
    setProducts(newProducts);
  };

  const productsRow = products.map((product, index) => (
    <ProductRow
      product={product}
      index={index}
      handleVariationChange={handleVariationChange}
      handleQuantityChange={handleQuantityChange}
      removeProduct={removeProduct}
      inventory={product.variations[product.selectedVariation].inventory}
      key={index}
    />
  ));

  return (
    <TableContainer
      component={Box}
      mt={3}
      p={2}
      border={1}
      borderRadius="borderRadius"
      borderColor="grey.300"
    >
      <Table>
        {products.length === 0 ? <caption>No orders added</caption> : null}
        <TableBody>{productsRow}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductsTable;
