import { Redirect, Route } from "react-router-dom";

import Details from "../components/Inventory/Details";
// Queries
import { INITIAL_QUERY } from "../Graphql/Queries/Orders";
import Inventory from "../components/Inventory";
// components
import { Loading } from "../components/common";
import Orders from "../components/Orders";
import Products from "../components/Products";
import Purchases from "../components/Purchases";
import React from "react";
import Reports from "../components/Reports";
import Returns from "../components/Returns";
import { useQuery } from "@apollo/client";

const AuthRoutes = ({ user }) => {
  const { loading } = useQuery(INITIAL_QUERY);
  if (loading) return <Loading />;

  return (
    <>
      <Route path="/orders" component={Orders} />
      <Route path="/returns" component={Returns} />
      <Route path="/inventory" component={Inventory} />
      <Route
        path="/purchases"
        render={props =>
          user.isSuperuser ? (
            <Purchases {...props} />
          ) : (
            <Redirect to="/orders" />
          )
        }
      />
      <Route
        path="/accounting"
        render={props =>
          user.isSuperuser ? <Details {...props} /> : <Redirect to="/orders" />
        }
      />
      <Route
        path="/reports"
        render={props =>
          user.isSuperuser ? <Reports {...props} /> : <Redirect to="/orders" />
        }
      />
      <Route
        path="/products"
        render={props =>
          user.isSuperuser ? <Products {...props} /> : <Redirect to="/orders" />
        }
      />
      <Route component={Orders} />
    </>
  );
};

export default AuthRoutes;
